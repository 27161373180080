/**
 * サーバーアクセス用サービス.
 *
 * @file services/index.js
 * @author Baek.JH (SBK)
 * @copyright Hitach Industry & Control Solutions, Ltd. All rights reserved.
 */

import axios from 'axios'
import { setInterceptor, setAuthenticationInterceptor } from './interceptor'
import { isNil, isEmpty } from 'lodash'

/**
 * 認証ヘッダを取得します.
 *
 * ユーザ情報が存在する場合は、アクセストークンが設定されたヘッダを返します.
 * '{Authorization: Bearer (access token)}'
 * ユーザ情報が存在しない場合は、空きヘッダを返します.'{}'
 *
 * @return {Object} 認証ヘッダ
 */
function authenticationHeader() {
  const user = JSON.parse(sessionStorage.getItem('user'))

  if (user && user.accessToken) {
    return { Authorization: 'Bearer ' + user.accessToken }
  } else {
    return {}
  }
}

/**
 * サーバーアクセス用サービスインスタンスを生成します.
 *
 * 認証前にサーバーへアクセスする際に使用します.
 * 生成後、インターセプターを設定してから返します.
 *
 * @see [setInterceptor()]{@link services/interceptor/setInterceptor()}
 *
 * @param {string} url URL
 *
 * @return {AxiosInstance} Axios インスタンス
 */
export function createService(url, accessToken = null) {
  let headers = {}
  if (accessToken) {
    headers = { Authorization: `Bearer ${accessToken}` }
  }

  const instance = axios.create(
    Object.assign({
      baseURL: url,
      headers: headers,
    })
  )

  setInterceptor(instance)
  return instance
}

/**
 * サーバーアクセス用サービスインスタンスを生成します.（認証ヘッダ込み）
 *
 * 認証後にサーバーへアクセスする際に使用します.
 * 生成後、インターセプターを設定してから返します.
 *
 * @see services/interceptor/setAuthenticationInterceptor()
 *
 * @param {string} url URL
 *
 * @return {AxiosInstance} Axios インスタンス
 */
export function createAuthenticaionService(url) {
  const instance = axios.create(
    Object.assign({ baseURL: url, headers: authenticationHeader() })
  )
  setAuthenticationInterceptor(instance)
  return instance
}
export function createAuthenticaionServiceForExcel(url) {
  const instance = axios.create(
    Object.assign({
      baseURL: url,
      headers: authenticationHeader(),
      responseType: 'blob',
    })
  )
  setAuthenticationInterceptor(instance)
  return instance
}

/**
 * 指定したパラメータ情報からクエリパラメータを作成します.
 *
 * @param {Object} params パラメータ
 *
 * @return {string} クエリパラメータ
 */
export function makeQueryParams(params) {
  // [escape] -> valueをエンコーディングしてURLへ設定
  const keys = Object.keys(params)
  const queryParams = keys
    .map((key) =>
      isNil(params[key]) || isEmpty(params[key])
        ? ''
        : `${key}=${encodeString(params[key])}&`
    )
    .join('')

  return queryParams
}

/**
 * データエンコーディング
 * @param {*} str
 */
function encodeString(str) {
  var rtnStr = ''
  rtnStr = encodeURIComponent(str)
  return rtnStr
}
