/**
 * 入力チェッカー.
 *
 * @file plugins/validate.js
 * @author Kim.HY (SBK)
 * @copyright Hitach Industry & Control Solutions, Ltd. All rights reserved.
 */

import Vue from 'vue'
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  //validate,
} from 'vee-validate'
import {
  required,
  max,
  min,
  max_value,
  min_value,
  integer,
  regex,
  //numeric,
} from 'vee-validate/dist/rules'

// グローバルコンポネントとして登録
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('validation-provider', ValidationProvider)

/**
 * メッセージを初期化します.
 */
export function initMessge(store) {
  extend('required', {
    ...required,
    message: store.getters['init/getMessage']('YZ00MG925E'),
    computesRequired: true,
  })

  extend('max', {
    ...max,
    message: store.getters['init/getMessage']('YZ00MG926E', '{length}'),
  })

  extend('min', {
    ...min,
    message: store.getters['init/getMessage']('YZ00MG927E', '{length}'),
  })

  extend('max_value', {
    ...max_value,
    message: store.getters['init/getMessage']('YZ00MG980E', '{max}'),
  })

  extend('min_value', {
    ...min_value,
    message: store.getters['init/getMessage']('YZ00MG981E', '{min}'),
  })

  // 数字のみ(0-9)
  extend('integer', {
    ...integer,
    message: store.getters['init/getMessage']('YZ00MG930E'),
  })

  extend('regex', {
    ...regex,
    message: store.getters['init/getMessage']('YZ00MG933E'),
  })

  // 全角文字
  extend('fullchar', {
    validate: (value) => {
      return value.match(/^[^\x01-\x7E\xA1-\xDF]+$/)
    },
    message: store.getters['init/getMessage']('YZ00MG931E'),
  })

  // 英数のみ
  extend('onlyAlphaNumeric', {
    validate: (value) => {
      return value.match(/^[0-9a-zA-Z]+$/)
    },
    message: store.getters['init/getMessage']('YZ00MG932E'),
  })

  // 英数記号のみ
  extend('alphaNumeric', {
    validate: (value) => {
      return value.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~()]+$/)
    },
    message: store.getters['init/getMessage']('YZ00MG932E'),
  })

  // 数字のみ(0-9)
  extend('numeric', {
    validate: (value) => {
      return String(value).match(/^[0-9.]+$/)
    },
    message: store.getters['init/getMessage']('YZ00MG930E'),
  })

  // Double/Float数字のみ(+0-9.0-9)
  extend('float', {
    validate: (value) => {
      //return String(value).match(/^[+-]?\d+(\.\d+)?$/)
      return String(value).match(/^[+]?\d+(\.\d+)?$/)
    },
    message: store.getters['init/getMessage']('YZ00MG985E'),
  })

  // 日付
  extend('date', {
    validate: (value) => {
      var regEx = /^\d{4}\/\d{2}\/\d{2}$/
      return value.match(regEx) && !isNaN(Date.parse(value))
    },
    message: store.getters['init/getMessage']('YZ00MG928E'),
  })

  // 時間
  extend('time', {
    validate: (value) => {
      // hh:mm:ss
      // var regEx = /(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)/

      // hh:mm
      var regEx = /(?:[01]\d|2[0-3]):(?:[0-5]\d)/
      return value.match(regEx)
    },
    message: store.getters['init/getMessage']('YZ00MG929E'),
  })

  // 郵便番号
  extend('zipNumeric', {
    validate: (value) => {
      //return value.match(/^\d{3}-?\d{4}$/u)
      return value.match(/^[0-9]+$/)
    },
    message: store.getters['init/getMessage']('YZ00MG968E'),
  })

  // 期間FROMと期間TOの日時整合性チェック
  extend('dateTimeRange', {
    params: ['target'],
    validate: (value, { target }) => {
      return !target || new Date(value).getTime() <= new Date(target).getTime()
    },
    // 日付の入力に不整合があります。
    message: store.getters['init/getMessage']('YZ00MG970E'),
  })

  // 期間FROMと期間TOの日付整合性チェック
  extend('dateRange', {
    params: ['target'],
    validate: (value, { target }) => {
      return !target || new Date(value) <= new Date(target)
    },
    // 期間日付の入力に不整合があります。
    message: store.getters['init/getMessage']('YZ00MG992E'),
  })

  // コード用入力整合性チェック
  extend('alphaNumericCode', {
    validate: (value) => {
      return value.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]+$/)
      //return value.match(/^[a-zA-Z0-9-/()]+$/)
    },
    message: store.getters['init/getMessage']('YZ00MG932E'),
  })

  // 数字のみ(+-0-9)
  extend('numberPositiveNegative', {
    validate: (value) => {
      return String(value).match(/^[+-]?[0-9]+$/)
    },
    message: store.getters['init/getMessage']('YZ00MG930E'),
  })

  // email
  extend('email', {
    validate: (value) => {
      return String(value).match(/\S+@\S+\.\S+/)
    },
    message: store.getters['init/getMessage']('YZ00MG933E'),
  })

  // 数字1-9
  extend('oneToNine', {
    validate: (value) => {
      return String(value).match(/^[1-9]$/)
    },
    message: store.getters['init/getMessage']('YZ00MG930EN'),
  })
}
