<template>
  <em data-class="base-icon" :class="iconClassList" />
</template>

<script>
export default {
  name: 'BaseIcon',

  props: {
    /**
     * アイコン名.
     * @type {String}
     */
    name: {
      type: String,
      default: '',
    },
  },

  computed: {
    /**
     * アイコンを表示するためのCSSクラスリスト.
     * @return {Array}
     */
    iconClassList() {
      return ['fa', `fa-${this.name}`]
    },
  },
}
</script>
