<template>
  <BaseFormGroup
    data-class="base-date-input-field"
    class="base-date-input-field"
    :columnInfo="fromColumnInfo"
    v-bind="$attrs"
  >
    <BaseDateFromTo
      :fromValue.sync="from"
      :toValue.sync="to"
      :fromColumnInfo="fromColumnInfo"
      :toColumnInfo="toColumnInfo"
      :error-visible="errorVisible"
      :fromRules="fromRules"
      :toRules="toRules"
      :rangeTarget="rangeTarget"
      :state="state"
      :locale="locale"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </BaseFormGroup>
</template>

<script>
export default {
  to: 'BaseDateFromToField',
  inheritAttrs: false,

  data() {
    return {
      from: this.fromValue,
      to: this.toValue,
    }
  },

  watch: {
    fromValue() {
      this.from = this.fromValue
    },

    toValue() {
      this.to = this.toValue
    },
  },

  props: {
    fromValue: {
      type: String,
      default: '',
    },

    toValue: {
      type: String,
      default: '',
    },

    locale: {
      type: String,
      default: 'ja',
    },

    /**
     * 定義情報.
     * @type {Object}
     * @requires
     */
    fromColumnInfo: {
      type: Object,
      required: true,
    },

    toColumnInfo: {
      type: Object,
      default: null,
    },
    /**
     * バリデーションチェックの状態を表示するか.
     * @type {Boolean}
     */
    state: {
      type: Boolean,
      default: false,
    },

    /**
     * エラーメッセージを表示するか.
     * @type {Boolean}
     */
    errorVisible: {
      type: Boolean,
      default: true,
    },

    /**
     * 入力検証ルール.
     * @type {String}
     */
    fromRules: {
      type: Array,
      default: () => [],
    },
    /**
     * 入力検証ルール.
     * @type {String}
     */
    toRules: {
      type: Array,
      default: () => [],
    },

    rangeTarget: {
      type: String,
      default: null,
    },
  },

  computed: {},
}
</script>
