<template>
  <div data-class="base-detail-page" class="main-padding">
    <BaseBreadcrumb v-if="breadcrumbItems" :items="breadcrumbItems" />
    <div v-if="buttonItems" class="ml-1 mr-1 mb-3 row justify-content-between">
      <div class="mb-2" v-for="(group, index) in buttonItems" :key="index">
        <BaseButton
          v-for="(item, index) in group"
          :key="index"
          class="mr-2"
          :variant="item.variant"
          :icon="item.icon"
          :columnInfo="item.columnInfo"
          :disabled="item.disabled"
          @click="item.onClickCallback"
        />
      </div>
    </div>
    <BaseTabGroup
      :inputModel="inputModel"
      :inputComponents="inputComponents"
    ></BaseTabGroup>
  </div>
</template>

<script>
export default {
  name: 'BaseDetailPage',
  inheritAttrs: false,
  props: {
    breadcrumbItems: { type: Array },
    inputComponents: {},
    inputModel: { type: Object },
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems: {
      type: Array,
      default: () => null,
    },
  },
}
</script>

<style lang="scss" scoped></style>
