<template>
  <!-- <b-overlay
    :show="show"
    variant="dark"
    opacity="0.5"
    spinner-variant="primary"
    no-wrap
  >
  </b-overlay> -->
  <Loading
    :active.sync="show"
    :is-full-page="true"
    loader="spinner"
    background-color="#121212"
    color="#0000ff"
  />
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'BaseOverlayLoading',
  components: {
    Loading,
  },
  props: {
    /**
     * ローディングが活性しているか.
     * @type {Boolean}
     */
    show: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
