<template>
  <div data-class="base-search-list-page" class="base-search-list-page">
    <BaseBreadcrumb v-if="breadcrumbItems" :items="breadcrumbItems" />
    <div class="row">
      <!-- col-xs-:<768px, col-sm-:>=768px, col-md-:>=992px, col-lg-:>=1200px-->
      <div class="col-xs-12 col-sm-6 col-md-5 col-lg-4 side">
        <BaseSearchCondition
          ref="base-search"
          :searchCondition="searchCondition"
          :searchConditionInfo="searchConditionInfo"
          v-bind="$attrs"
          v-on="$listeners"
        >
          <template #search-condition-ex>
            <slot name="search-condition-ex" />
          </template>
        </BaseSearchCondition>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-7 col-lg-8 main">
        <div
          v-if="buttonItems"
          class="ml-1 mr-1 mb-3 row justify-content-between"
        >
          <div class="mb-2" v-for="(group, index) in buttonItems" :key="index">
            <BaseButton
              v-for="(item, index) in group"
              :key="index"
              class="mr-2"
              :variant="item.variant"
              :icon="item.icon"
              :columnInfo="item.columnInfo"
              :disabled="item.disabled"
              @click="item.onClickCallback"
            />
          </div>
        </div>
        <slot name="list-header" />
        <section data-class="work-list-table">
          <!-- テーブル -->
          <BasePagingTable
            v-model="searchCondition.size"
            ref="list-table"
            id="list-table"
            :selectMode="selectMode"
            :fields="fields"
            :items="tableItems"
            :columnInfoPage="pagingInfo.page"
            :columnInfoSize="pagingInfo.size"
            :optionsPage="getListOptions(MASTER_CODE.LIST_PAGE, false)"
            :optionsSize="getListOptions(MASTER_CODE.LIST_SIZE, false)"
            :perPage="perPageX"
            :screenId="screenId"
            @row-selected="onRowSelected"
            @size-changed="updateSize"
            @per-page-changed="updatePerPage"
            height="660px"
            @clickVehicleOperationCell="clickVehicleOperationCell"
          />
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { getListOptions, getCountPerPage } from '@/common/Common.js'

export default {
  name: 'BaseSearchListPage',
  inheritAttrs: false,
  props: {
    breadcrumbItems: { type: Array },
    searchConditionInfo: {},
    searchCondition: { type: Object },
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems: {
      type: Array,
      default: () => null,
    },
    columnItems: {
      type: Array,
      default: () => null,
    },
    tableItems: { type: Array },
    fields: { type: Array, required: true },
    /**
     * 選択モード.
     * @type {String}
     */
    selectMode: {
      type: String,
      default: 'single',
    },
    screenId: {
      type: String,
    },
  },
  data() {
    return {
      perPageX: getCountPerPage(),
    }
  },
  computed: {
    pagingInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'paging'
      )
    },
  },
  methods: {
    getListOptions,
    onRowSelected(selectedItems) {
      this.$emit('row-selected', selectedItems)
    },
    updateSize: function (size) {
      this.$emit('max-count-changed', size)
    },
    updatePerPage: function (perPage) {
      this.perPageX = perPage
    },

    clickVehicleOperationCell(rowItem, isKind) {
      this.$emit('clickVehicleOperationCell', rowItem, isKind)
    },
  },
}
</script>

<style lang="scss" scoped>
.base-search-list-page {
  .main {
    @include mq(xsmall) {
      margin: 0.5rem 0rem;
    }
    @include mq() {
      margin: 0 0 1rem 0;
    }
  }
  .side {
    min-width: 310px;
  }
}
</style>
