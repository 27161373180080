<template>
  <div data-class="list-page-layout" class="main-padding">
    <BaseBreadcrumb v-if="breadcrumbItems" :items="breadcrumbItems" />
    <div v-if="buttonItems" class="ml-1 mr-1 mb-3 row justify-content-between">
      <div class="mb-2" v-for="(group, index) in buttonItems" :key="index">
        <BaseButton
          v-for="(item, index) in group"
          :key="index"
          class="mr-2"
          :variant="item.variant"
          :icon="item.icon"
          :columnInfo="item.columnInfo"
          :disabled="item.disabled"
          :toggleLabel="item.toggleLabel"
          :toggleVariant="item.toggleVariant"
          :isToggled="item.isToggled"
          @click="item.onClickCallback"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <slot name="top"></slot>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-4 col-lg-6 side">
        <slot name="side"></slot>
      </div>
      <div class="col-sm-12 col-md-8 col-lg-6 main">
        <slot name="main"></slot>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <slot name="bottom"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DetailPageLayout',
  props: {
    breadcrumbItems: { type: Array },
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems: {
      type: Array,
      default: () => null,
    },
  },
}
</script>

<style lang="scss" scoped></style>
