<template>
  <BaseFormGroup
    data-class="base-time-input-field"
    class="base-time-input-field"
    :columnInfo="fromColumnInfo"
    v-bind="$attrs"
  >
    <BaseTimeFromTo
      :fromValue.sync="_fromValue"
      :toValue.sync="_toValue"
      :fromColumnInfo="fromColumnInfo"
      :toColumnInfo="toColumnInfo"
      :error-visible="errorVisible"
      :fromRules="fromRules"
      :toRules="toRules"
      :rangeTarget="rangeTarget"
      :state="state"
      :locale="locale"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </BaseFormGroup>
</template>

<script>
export default {
  to: 'BaseTimeFromToField',
  inheritAttrs: false,

  props: {
    fromValue: {
      type: String,
      default: '',
    },

    toValue: {
      type: String,
      default: '',
    },

    locale: {
      type: String,
      default: 'ja',
    },

    /**
     * 定義情報.
     * @type {Object}
     * @requires
     */
    fromColumnInfo: {
      type: Object,
      required: true,
    },

    toColumnInfo: {
      type: Object,
      default: null,
    },
    /**
     * バリデーションチェックの状態を表示するか.
     * @type {Boolean}
     */
    state: {
      type: Boolean,
      default: false,
    },

    /**
     * エラーメッセージを表示するか.
     * @type {Boolean}
     */
    errorVisible: {
      type: Boolean,
      default: true,
    },

    /**
     * 入力検証ルール.
     * @type {String}
     */
    fromRules: {
      type: Array,
      default: () => [],
    },
    /**
     * 入力検証ルール.
     * @type {String}
     */
    toRules: {
      type: Array,
      default: () => [],
    },

    rangeTarget: {
      type: String,
      default: null,
    },
  },

  computed: {
    _fromValue: {
      get() {
        return this.fromValue
      },
    },
    _toValue: {
      get() {
        return this.toValue
      },
    },
  },
}
</script>
