import { render, staticRenderFns } from "./BaseBreadcrumb.vue?vue&type=template&id=02b4352e&scoped=true&"
import script from "./BaseBreadcrumb.vue?vue&type=script&lang=js&"
export * from "./BaseBreadcrumb.vue?vue&type=script&lang=js&"
import style0 from "./BaseBreadcrumb.vue?vue&type=style&index=0&id=02b4352e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02b4352e",
  null
  
)

export default component.exports