<script>
import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  name: 'BaseChartBar',
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  watch: {
    chartData: function () {
      this.update()
    },
    options: function () {
      this.update()
    },
  },
  mounted() {
    this.update()
  },
  methods: {
    update: function () {
      this.renderChart(this.chartData, this.options)
    },
  },
}
</script>
