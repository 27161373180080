import { createAuthenticaionService, makeQueryParams } from '@/services/index'

const BASE_URL = `${process.env.VUE_APP_API_URL}ganttchart/`

class ManualPlanningService {
  getAll() {
    return createAuthenticaionService(BASE_URL).get('/')
  }

  get(id) {
    return createAuthenticaionService(BASE_URL).get(`/${id}`)
  }

  search(searchCondition) {
    const param = makeQueryParams(searchCondition)
    return createAuthenticaionService(`${BASE_URL}search/`).get(`/?${param}`)
  }

  // 割付処理
  registAssign(data) {
    return createAuthenticaionService(`${BASE_URL}registassign/`).put('/', data)
  }
  // 確定処理
  registConfirm(data) {
    return createAuthenticaionService(`${BASE_URL}registconfirm/`).put(
      '/',
      data
    )
  }
  // 確定解除処理
  registConfirmCancel(data) {
    return createAuthenticaionService(`${BASE_URL}registconfirmcancel/`).put(
      '/',
      data
    )
  }

  // 確定、確定解除後の検索処理
  searchAfterConfirmCancel(searchCondition) {
    const param = makeQueryParams(searchCondition)
    return createAuthenticaionService(
      `${BASE_URL}searchafterconfirmcancel/`
    ).get(`/?${param}`)
  }

  // 割付後Timerの検索処理
  searchTimerAssigned(data) {
    return createAuthenticaionService(`${BASE_URL}searchtimerassigned/`).put(
      `/`,
      data
    )
  }

  // 登録処理
  async registRun(data) {
    return createAuthenticaionService(`${BASE_URL}registrun/`).put('/', data)
  }

  // 未割付検索
  searchunassigned(searchCondition) {
    const param = makeQueryParams(searchCondition)
    return createAuthenticaionService(`${BASE_URL}searchunassigned/`).get(
      `/?${param}`
    )
  }

  // 編集処理終了
  closeList(id) {
    return createAuthenticaionService(`${BASE_URL}closelist/`).put(`/${id}`)
  }
}

export default new ManualPlanningService()
