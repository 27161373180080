<template>
  <div data-class="list-page-layout" class="list-page-layout">
    <div class="row">
      <div class="col-sm-12 col-md-4 col-lg-3 side">
        <slot name="side"></slot>
      </div>
      <div class="col-sm-12 col-md-8 col-lg-9 main">
        <div
          v-if="buttonItems"
          class="ml-1 mr-1 mb-3 row justify-content-between"
        >
          <div class="mb-2" v-for="(group, index) in buttonItems" :key="index">
            <BaseButton
              v-for="(item, index) in group"
              :key="index"
              class="mr-2"
              :variant="item.variant"
              :icon="item.icon"
              :columnInfo="item.columnInfo"
              :disabled="item.disabled"
              @click="item.onClickCallback"
            />
          </div>
        </div>
        <slot name="main"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListPageLayout',

  props: {
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems: {
      type: Array,
      default: () => null,
    },
  },
}
</script>

<style lang="scss" scoped>
.list-page-layout {
  .main {
    @include mq(xsmall) {
      margin: 0.5rem 0rem;
    }
    @include mq() {
      margin: 0 0 1rem 0;
    }
  }
}
</style>
