<template>
  <b-card
    class="base-search-condition"
    border-variant="primary"
    :header="searchConditionInfo.header"
    header-bg-variant="primary"
    header-text-variant="white"
  >
    <div class="mb-3">
      <!-- クリアボタン -->
      <BaseButton
        class="mr-2"
        variant="success"
        icon="times-circle"
        :columnInfo="searchConditionInfo.clear"
        @click="clear"
      />
      <!-- 検索ボタン -->
      <BaseButton
        variant="success"
        icon="search"
        :columnInfo="searchConditionInfo.search"
        @click="search"
      />
    </div>
    <ValidationObserver ref="base-search-condition">
      <b-container fluid="xl" style="padding: 0px">
        <b-form-row
          v-for="(item, index) in searchConditionInfo.inputComponents"
          :key="index"
        >
          <b-col>
            <BaseTextInputField
              v-if="item.type === 'text'"
              v-model="searchCondition[item.id]"
              :columnInfo="item.columnInfo"
              :rules="item.rules"
              :maxLength="item.maxLength"
              :minLength="item.minLength"
            />
            <BaseSelectField
              v-else-if="item.type === 'select'"
              v-model="searchCondition[item.id]"
              :columnInfo="item.columnInfo"
              :options="item.options"
              :rules="item.rules"
              @change="(obj) => item.onChangeCallback(obj)"
            />
            <BaseDateInputField
              v-else-if="item.type === 'date'"
              v-model="searchCondition[item.id]"
              :columnInfo="item.columnInfo"
              :rules="item.rules"
            />
            <BaseTimeInputField
              v-else-if="item.type === 'time'"
              v-model="searchCondition[item.id]"
              :columnInfo="item.columnInfo"
              :rules="item.rules"
            />
            <BaseCheckboxGroupField
              v-else-if="item.type === 'checkboxgroup'"
              v-model="searchCondition[item.id]"
              :columnInfo="item.columnInfo"
              :options="item.options"
              :rules="item.rules"
            />
            <BaseReferenceCodeNameField
              v-else-if="item.type === 'referenceCodeName'"
              :codeValue.sync="searchCondition[item.codeId]"
              :nameValue.sync="searchCondition[item.nameId]"
              :codeColumnInfo="item.codeColumnInfo"
              :nameColumnInfo="item.nameColumnInfo"
              :btnColumnInfo="item.btnColumnInfo"
              :codeRules="item.codeRules"
              :nameRules="item.nameRules"
              :codeMaxLength="item.codeMaxLength"
              :codeMinLength="item.codeMinLength"
              :nameMaxLength="item.nameMaxLength"
              :nameMinLength="item.nameMinLength"
              @click="(obj) => item.onClickCallback(obj)"
            />
            <BaseReferenceCodeNameField
              v-else-if="item.type === 'referenceCode'"
              :codeValue.sync="searchCondition[item.codeId]"
              :codeColumnInfo="item.codeColumnInfo"
              :btnColumnInfo="item.btnColumnInfo"
              :codeRules="item.codeRules"
              :codeMaxLength="item.codeMaxLength"
              :codeMinLength="item.codeMinLength"
              @click="(obj) => item.onClickCallback(obj)"
            />
            <BaseTextInputGroupField
              v-else-if="item.type === 'textGroup'"
              :id1.sync="searchCondition[item.id1]"
              :id2.sync="searchCondition[item.id2]"
              :id3.sync="searchCondition[item.id3]"
              :id4.sync="searchCondition[item.id4]"
              :columnInfo1="item.columnInfo1"
              :columnInfo2="item.columnInfo2"
              :columnInfo3="item.columnInfo3"
              :columnInfo4="item.columnInfo4"
              :rule1="item.rule1"
              :rule2="item.rule2"
              :rule3="item.rule3"
              :rule4="item.rule4"
            />
            <BaseDateFromToField
              v-else-if="item.type === 'dateFromTo'"
              :fromValue.sync="searchCondition[item.fromId]"
              :toValue.sync="searchCondition[item.toId]"
              :fromColumnInfo="item.fromColumnInfo"
              :toColumnInfo="item.toColumnInfo"
              :fromRules="item.fromRules"
              :toRules="item.toRules"
              :locale="item.locale"
              :rangeTarget="item.toColumnInfo.domainColumnName"
            />
            <!-- rangeTargetがlabelの場合、fromとtoのラベルが同一な場合、区分ができない。 domainColumnNameはidとnameに設定されるため、fromとtoの区分が出来る-->
            <!-- :rangeTarget="item.toColumnInfo.label" -->
            <BaseTimeFromToField
              v-else-if="item.type === 'timeFromTo'"
              :fromValue.sync="searchCondition[item.fromId]"
              :toValue.sync="searchCondition[item.toId]"
              :fromColumnInfo="item.fromColumnInfo"
              :toColumnInfo="item.toColumnInfo"
              :fromRules="item.fromRules"
              :toRules="item.toRules"
              :locale="item.locale"
              :rangeTarget="item.toColumnInfo.domainColumnName"
            />
            <!-- :rangeTarget="item.toColumnInfo.label" -->
            <BaseDateTimeField
              v-else-if="item.type === 'dateTime'"
              :dateValue.sync="searchCondition[item.dateId]"
              :timeValue.sync="searchCondition[item.timeId]"
              :dateColumnInfo="item.dateColumnInfo"
              :timeColumnInfo="item.timeColumnInfo"
              :dateRules="item.dateRules"
              :timeRules="item.timeRules"
            />
            <BaseDateTimeFromToField
              v-else-if="item.type === 'dateTimeFromTo'"
              :dateFromValue.sync="searchCondition[item.dateFromId]"
              :dateToValue.sync="searchCondition[item.dateToId]"
              :timeFromValue.sync="searchCondition[item.timeFromId]"
              :timeToValue.sync="searchCondition[item.timeToId]"
              :dateFromColumnInfo="item.dateFromColumnInfo"
              :dateToColumnInfo="item.dateToColumnInfo"
              :timeFromColumnInfo="item.timeFromColumnInfo"
              :timeToColumnInfo="item.timeToColumnInfo"
              :dateFromRules="item.dateFromRules"
              :dateToRules="item.dateToRules"
              :timeFromRules="item.timeFromRules"
              :timeToRules="item.timeToRules"
              :locale="item.locale"
              :rangeTarget="item.timeToColumnInfo.domainColumnName"
            />
            <!-- :rangeTarget="item.timeToColumnInfo.label" -->
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <slot name="search-condition-ex" />
          </b-col>
        </b-form-row>
      </b-container>
    </ValidationObserver>
  </b-card>
</template>

<script>
export default {
  name: 'BaseSearchCondition',
  inheritAttrs: false,
  props: {
    /**
     * 検索パラメータ.
     * @type {Object}
     */
    searchCondition: {
      type: Object,
      required: true,
    },

    /**
     * 項目定義情報.
     * @type {Object}
     */
    searchConditionInfo: {
      type: Object,
      required: true,
    },
  },
  methods: {
    /**
     * 検索します.
     * 具体的な実装は使用側に委譲します.
     */
    async search() {
      const validated = await this.$refs['base-search-condition'].validate()
      if (!validated) return
      this.$emit('search')
    },

    /**
     * 検索フォームをクリアします.
     * 具体的な実装は使用側に委譲します.
     */
    clear() {
      this.$emit('clear')
    },
  },
}
</script>

<style lang="scss" scoped>
.base-search-condition {
  min-height: 88%;
}
</style>
