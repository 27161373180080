import * as msal from 'msal'

let msalApp

export default {
  async configure(tenantId, clientId) {
    // configure は一回のみ実行
    if (msalApp) {
      return
    }

    // clientId未設定時、Azure AD 未使用
    if (!clientId) {
      return
    }
    if (!tenantId) {
      tenantId = 'common'
    }
    let authority = `https://login.microsoftonline.com/${tenantId}/`

    const config = {
      auth: {
        clientId: clientId,
        redirectUri: window.location.origin,
        authority: authority,
      },
      cache: {
        cacheLocation: 'sessionStorage',
      },
    }
    console.log('### Azure AD sign-in: enabled\n', config)

    msalApp = new msal.UserAgentApplication(config)
  },

  clientId() {
    if (!msalApp) {
      return null
    }

    return msalApp.clientId
  },

  async login() {
    if (!msalApp) {
      return
    }

    const LOGIN_SCOPES = ['user.read', 'openid', 'profile', 'email']
    await msalApp.loginPopup({
      scopes: LOGIN_SCOPES,
      prompt: 'select_account',
    })
  },

  async logout() {
    if (!msalApp) {
      return
    }

    await msalApp.logout()
  },

  user() {
    if (!msalApp) {
      return null
    }

    return msalApp.getAccount()
  },

  async acquireToken() {
    if (!msalApp) {
      return null
    }

    const accessTokenRequest = {
      scopes: ['user.read'],
    }

    let tokenResp
    try {
      // 1. Silent取得
      tokenResp = await msalApp.acquireTokenSilent(accessTokenRequest)
    } catch (err) {
      // 2. Silent失敗時 Popup取得
      tokenResp = await msalApp.acquireTokenPopup(accessTokenRequest)
    }

    if (!tokenResp.accessToken) {
      throw new Error('認証情報が存在しません。')
    }

    return tokenResp.accessToken
  },

  clearLocal() {
    if (msalApp) {
      msalApp.cacheStorage.clear()
    }
  },
}
