<template>
  <div id="mapView">
    <LMap :zoom="zoom" :center="center" @ready="onMapReady" @click="onMapClick">
      <LTileLayer :url="url" :attribution="attribution"></LTileLayer>
      <LMarker :lat-lng="marker"></LMarker>
    </LMap>
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
import L from 'leaflet'

delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  props: {
    center: {
      type: Array,
      default: () => [35.70241, 139.7754],
    },
    markerPosition: {
      type: Array,
      default: () => [35.70241, 139.7754],
    },
  },
  data() {
    return {
      map: {},
      zoom: 15,
      url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    }
  },
  computed: {
    marker() {
      return L.latLng(this.markerPosition[0], this.markerPosition[1])
    },
  },
  methods: {
    onMapReady(mapObject) {
      this.map = mapObject
    },

    onMapClick(e) {
      console.log(e)
      this.$emit('clicked', e.latlng)
    },
  },
}
</script>

<style scoped>
#mapView {
  height: 100%;
}
body {
  margin: 0;
}
</style>
