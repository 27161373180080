import Vue from 'vue'

export const SCREEN_ID = {
  COMMON: 'COMMON',
  LOGIN: 'A001-00',
  MENU: 'B001-00', // メニュー
  DASHBOARD: 'J001-00', // ダッシュボード
  NOTICE_MODAL: 'J001-01', // インフォメーション
  BLOCK_SELECT: 'B002-00', // ブロック選択
  LANG_SELECT: 'B002-01', // 言語選択
  LOGMANAGEMENT_LIST: 'B003-10', // ログ検索
  INFORMATION_LIST: 'B004-10', // インフォメーション検索
  INFORMATION_DETAIL: 'B004-20', // インフォメーション詳細
  USER_LIST: 'B005-10', // ユーザ検索
  USER_DETAIL: 'B005-20', // ユーザ詳細
  PASSWORD_CHANGE: 'B005-21',
  SALES_INFO: 'C001-00', // 販売情報
  RECEIVE_ORDER_LIST: 'C001-10', // 受注オーダー検索
  RECEIVE_ORDER_DETAIL: 'C001-20', // 受注オーダー詳細
  RECEIVE_ORDER_DETAIL_EDIT: 'C001-50', // 受注オーダー詳細TABLE編集
  REGULAR_ORDER_LIST: 'C002-10', // 定期オーダー生成設定検索
  REGULAR_ORDER_DETAIL: 'C002-20', // 定期オーダー生成設定詳細
  PLANNING_ORDER_LIST: 'D001-10', // 配車オーダー検索
  PLANNING_ORDER_DETAIL: 'D001-20', // 配車オーダー詳細
  ORDER_PACKUP_MODAL: 'D001-40', // 荷まとめ設定
  PLANNING_ORDER_PACKUP_MODAL: 'D001-41', // 配送まとめ設定
  ORDER_COUNT_SPLIT_MODAL: 'D001-42', // 数量分割設定
  ORDER_SECTION_SPLIT_MODAL: 'D001-43', // 区間分割設定
  PLANNING_PATTERN_LIST: 'D002-10', // 配車計画パターン検索
  PLANNING_PATTERN_DETAIL: 'D002-20', // 配車計画パターン詳細
  PLANNING_EVALUATION_LIST: 'D003-10', // 配車計画評価設定検索
  PLANNING_EVALUATION_DETAIL: 'D003-20', // 配車計画評価設定詳細
  VEHICLE_OPERATION_LIST: 'D004-10', // 稼動情報検索
  VEHICLE_OPERATION_LIST_SUB: 'D004-10-SUB',
  VEHICLE_OPERATION_DETAIL: 'D004-20', // 稼動情報詳細
  AUTO_PLANNING_LIST: 'D005-10', // 自動配車結果検索
  AUTO_PLANNING_DETAIL: 'D005-20', // 自動配車結果詳細
  AUTO_PLANNING_REQUEST_MODAL: 'D005-30', // 自動配車計画指示
  MANUAL_PLANNING_SEARCH: 'D006-10', // 手動配車検索
  MANUAL_PLANNING_VEHICLE_GROUP_REG: 'D006-10-REG',
  UNASSIGNED_ORDER_SEARCH: 'D006-11', // 未割付オーダー検索
  MANUAL_PLANNING_EDIT: 'D006-20', // 手動配車編集
  ROUTE_MAP: 'D006-90', // ルート表示
  GANTT_VIEW: 'D006-91', // ガントチャート表示
  PLACE_ORDER_LIST: 'E001-10', // 発注検索
  PLACE_ORDER_DETAIL: 'E001-20', // 発注詳細
  TRANSPORT_REQUEST_LIST: 'E002-10', // 運送依頼検索
  TRANSPORT_REQUEST_DETAIL: 'E002-20', // 運送依頼詳細
  WORK_RECORD_LIST: 'F001-10', // 荷積荷卸検索
  WORK_RECORD_LIST_SUB: 'F001-10-SUB', // 荷積荷卸の一括登録
  WORK_RECORD_DETAIL: 'F001-20', // 荷積荷卸詳細
  BILLING_LIST: 'G001-10', // 請求情報検索
  BILLING_DETAIL: 'G001-20', // 請求情報詳細
  PAYMENT_LIST: 'G002-10', // 支払情報検索
  PAYMENT_DETAIL: 'G002-20', // 支払情報詳細
  AMOUNT_EDIT_MODAL: 'G002-40', // 金額設定編集Modal
  FARE_LIST: 'G003-10', // 運賃検索
  DEPARTMENT_LIST: 'H001-10', // 部署検索
  DEPARTMENT_DETAIL: 'H001-20', // 部署詳細
  DEPARTMENT_MODAL_LIST: 'H001-30', // 部署参照
  CONTACT_MAIL_MODAL_LIST: 'H001-40', //部署メール送信先
  PLACE_LIST: 'H002-10', // 場所検索
  PLACE_DETAIL: 'H002-20', // 場所詳細
  PLACE_MODAL_LIST: 'H002-30', // 場所参照
  PLACE_SAME_SETTING: 'H002-40', // 同一場所設定
  VEHICLE_LIST: 'H003-10', // 輸送手段検索
  VEHICLE_DETAIL: 'H003-20', // 輸送手段詳細
  VEHICLE_MODAL_LIST: 'H003-30', // 輸送手段参照
  VEHICLE_INFLOW_IMPOSSIBLE: 'H003-40', // 流入不可設定
  VEHICLE_GROUP_LIST: 'H004-10', // 輸送手段グループ検索
  VEHICLE_GROUP_DETAIL: 'H004-20', // 輸送手段グループ詳細
  VEHICLE_GROUP_MODAL_LIST: 'H004-30', // 輸送手段グループ参照
  PRODUCT_PATTERN_LIST: 'H005-10', // 荷姿パターン検索
  PRODUCT_PATTERN_DETAIL: 'H005-20', // 荷姿パターン詳細
  PRODUCT_PATTERN_MODAL_LIST: 'H005-30', // 荷姿パターン参照
  PRODUCT_LIST: 'H006-10', // 荷姿検索
  PRODUCT_DETAIL: 'H006-20', // 荷姿詳細
  PRODUCT_MODAL_LIST: 'H006-30', // 荷姿参照
  POSTAL_CODE_MODAL_LIST: 'H007-30', // 郵便番号参照
  CITY_MODAL_LIST: 'H008-30', // 市区町村参照
  PREFECTURE_MODAL_LIST: 'H008-31', // 都道府県参照
  OPERATION_TIME_PATTERN_LIST: 'H009-10', // 稼動時間パターン検索
  OPERATION_TIME_PATTERN_DETAIL: 'H009-20', // 稼動時間パターン詳細
  OPERATION_TIME_PATTERN_MODAL_LIST: 'H009-30', // 稼動時間パターン参照
  OPERATION_TIME_PATTERN_EDIT_MODAL: 'H009-40', // 稼動時間パターン編集
  AREA_LIST: 'H010-10', // 方面・コース検索
  AREA_DETAIL: 'H010-20', // 方面・コース詳細
  AREA_MODAL_LIST: 'H010-30', // 方面・コース参照
  CALENDAR_LIST: 'H011-10', // カレンダー検索
  CALENDAR_DETAIL: 'H011-20', // カレンダー詳細
  CALENDAR_MODAL_LIST: 'H011-30', // カレンダー参照
  SECTION_DIVISION_LIST: 'H012-10', // 区間分割パターン検索
  SECTION_DIVISION_DETAIL: 'H012-20', // 区間分割パターン詳細
  SECTION_DIVISION_MODAL_LIST: 'H012-30', // 区間分割パターン参照
  SECTION_DIVISION_EDIT: 'H012-40', // 区間分割パターン編集
  TARIFF_LIST: 'H013-10', // タリフ検索
  TARIFF_DETAIL: 'H013-20', // タリフ詳細
  TARIFF_MODAL_LIST: 'H013-30', // タリフ参照
  TARIFF_EDIT_MODAL: 'H013-40', // タリフ編集Modal
  SURCHARGE_EDIT_MODAL: 'H013-50', // 燃調金編集Modal
  FREIGHT_ITEM_LIST: 'H014-10', // 運賃項目検索
  FREIGHT_ITEM_DETAIL: 'H014-20', // 運賃項目詳細
  FREIGHT_ITEM_MODAL_LIST: 'H014-30', // 運賃項目参照
  PASSAGE_PERMIT_LIST: 'H015-10', // 特殊車両通行許可検索
  PASSAGE_PERMIT_DETAIL: 'H015-20', // 特殊車両通行許可詳細
  PASSAGE_PERMIT_MODAL_LIST: 'H015-30', // 特殊車両通行許可参照
  REAL_VEHICLE_LIST: 'H016-10', // 実車番検索
  REAL_VEHICLE_DETAIL: 'H016-20', // 実車番詳細
  REAL_VEHICLE_MODAL_LIST: 'H016-30', // 実車番参照
  SIMULATION_DATA_GENERATION: 'I001', // シミュレーションデータ生成
}

export const MASTER_CODE = {
  // s010_codes ではないテーブルから取得するデータ
  /** m180_planning_groups */
  PLANNING_GROUP: 'list_plan_grp_cd', // 配車計画グループ
  /** s070_blocks */
  BLOCK: 'list_block', // ブロック
  /** m020_places */
  PREFECTURE: 'list_pref', // 都道府県
  /** m100_calendars */
  CALENDAR: 'list_calendar', // カレンダー
  /** s080_roles */
  ROLE: 'list_role', // ロール
  /** t060_cost_evaluation_tariff_names */
  TARIFF: 'list_tariff', // タリフ

  ACCURACY_LEVEL: 'accuracy_level', // 位置変換済区分
  AREA_ENTRY_KIND: 'area_entry_kind', // 方面登録区分
  AREA_KIND: 'area_kind', // 方面コース区分
  AUTO_PLANNING_CHECK_LEVEL: 'auto_planning_check_level', // 自動配車チェックレベル
  AUTO_PLANNING_EXCLUDE_FLAG: 'auto_planning_exclude_flag', // 自動配車対象外フラグ
  AUTO_PLANNING_LOGIC: 'auto_planning_logic', // 自動配車ロジック
  BANCHI_FLAG: 'banchi_flag', // 小字毎に番地フラグ
  CANCELED: 'canceled', // 取り消しフラグ
  CARGO_VEHICLE_KIND: 'cargo_vehicle_kind', // トラック／トレーラ区分
  CHANGE_KIND: 'change_kind', // 変更区分
  CHANGE_NOTICE_STATUS: 'change_notice_status', // 変更通知ステータス
  CHANGE_REASON_FLAG: 'change_reason_flag', // 変更理由フラグ
  CHOUME_FLAG: 'choume_flag', // 丁目有
  ADJUSTMENT_MONEY_KIND: 'adjustment_money_kind', //調整金区分
  CONTROL_CODE01: 'control_code01', // 制御コード1
  CONTROL_CODE02: 'control_code02', // 制御コード2
  CONTROL_CODE03: 'control_code03', // 制御コード3
  CONTROL_CODE04: 'control_code04', // 制御コード4
  CONTROL_CODE05: 'control_code05', // 制御コード5
  CONTROL_CODE06: 'control_code06', // 制御コード6
  CONTROL_CODE07: 'control_code07', // 制御コード7
  CONTROL_CODE08: 'control_code08', // 制御コード8
  CONTROL_CODE09: 'control_code09', // 制御コード9
  CONTROL_CODE10: 'control_code10', // 制御コード10
  COST_STATUS: 'cost_status', // 請求支払ステータス
  COST_TYPE: 'cost_type', // 請求支払区分
  COUNT_PARTITION_FLAG: 'count_partition_flag', // 数量分割フラグ
  COUNT_UNIT: 'count_unit', // 数量単位
  DATA_REGISTRATION_TYPE: 'data_registration_type', // データ作成区分
  DECISION_FLAG: 'decision_flag', // 確定フラグ
  DEFAULT_TYPE: 'default_type', // デフォルト区分
  DELETE_IMPOSSIBLE_KIND: 'delete_impossible_kind', // 削除可否区分
  DETACHABLE: 'detachable', // 着脱区分
  DETAIL_DATA_KIND: 'detail_data_kind', // 詳細データ区分
  DELETE_STATUS: 'delete_status', // 削除ステータス
  DELETED: 'deleted', // 削除済み区分
  DELETED_KIND: 'deleted_kind', // 削除表示
  DEPARTMENT_KIND: 'department_kind', // 部署区分
  DISPLAY_KIND: 'display_kind', // 表示区分
  DISPLAY_LEVEL: 'display_level', // ログ：画面表示レベル
  EDIT_FLAG: 'edit_flag', // 変更フラグ
  ENTRY_USER_KIND: 'entry_user_kind', // 登録者区分
  FOR_OVERSEAS: 'for_overseas', // 海外向け対象
  HIGHWAY_USE_YN_FLAG: 'highway_use_yn_flag', // 高速道路使用可否フラグ
  HISTORY_TYPE: 'history_type', // 履歴区分
  INCLUDE: 'include', // 別梱区分
  INFORMATION_RELEASE_LEVEL: 'information_release_level', // 表示権限
  IS_ADJUSTMENT_CALCULATION: 'is_adjustment_calculation', // 燃料調整金計算対象
  IS_MULTI_FREIGHT_ITEM: 'is_multi_freight_item', // 複数運賃項目有無
  IS_MULTIPLE_LOADING: 'is_multiple_loading', // 2台積区分
  IS_ONETIME_MASTER: 'is_onetime_master', // マスタ区分
  IS_SIMULATION_DATA: 'is_simulation_data', // シミュレーション取込データフラグ
  LARGE_KIND: 'large_kind', // 大区分
  LANGUAGE: 'language', // 言語
  LIST_PAGE: 'list_page', //
  LIST_SIZE: 'list_size', //
  LOAD_FLAG: 'load_flag', // 積卸区分
  MAIL_SEND_STATUS: 'mail_send_status', // メール送信ステータス
  MAIL_TYPE: 'mail_type', // メール区分
  MANAGEMENT_VEHICLE_KIND: 'management_vehicle_kind', // 仮車区分
  MANUAL_INPUT: 'manual_input', // 運賃手入力区分
  MANUAL_PLANNING_CHECK_LEVEL: 'manual_planning_check_level', // 手動配車チェックレベル
  MESSAGE_KIND: 'message_kind', // メッセージ区分
  MIDDLE_KIND_IF: 'middle_kind_if', // 中区分(通信)
  MIDDLE_KIND_BATCH: 'middle_kind_batch', // 中区分(バッチ)
  MIDDLE_KIND_LOGIN: 'middle_kind_login', // 中区分(ログイン)
  MIDDLE_KIND_MASTER: 'middle_kind_master', // 中区分(マスタ履歴)
  MIXLOAD_IMPOSSIBLE_FLAG: 'mixload_impossible_flag', // 混載不可フラグ
  MODAL_TYPE: 'modal_type', // モーダル区分
  NEED_APPROVAL: 'need_approval', // 承認要否
  NEED_GUIDE: 'need_guide', // 要誘導
  NEED_PERMIT_CHECK: 'need_permit_check', // 許可証チェック要否
  ONEWAY_OR_ROUND: 'oneway_or_round', // 往復区分
  GO_OR_BACK: 'go_or_back', // 往路復路区分
  OPERATION_TIME_PATTERN_VALID_FLAG: 'operation_time_pattern_valid_flag', // 稼動時間パターン有効フラグ
  ORDER_KIND: 'order_kind', // オーダー区分
  ORDER_STATUS: 'order_status', // オーダーステータス
  PACKUP_FLAG: 'packup_flag', // まとめフラグ
  PARAMETER_TYPE: 'parameter_type', // パラメータ型
  PAID_UP: 'paid_up', // 払出済フラグ
  PERIOD_CODE: 'period_code', // 期間
  PERMIT_CHECK_STATUS: 'permit_check_status', // 許可証チェック状態
  PERMIT_CONDITION: 'permit_condition', // 許可証条件
  PERMIT_REFUSE: 'permit_refuse', // 承諾・拒否区分
  PLACE_ASSIGN_FLAG: 'place_assign_flag', // 場所指定フラグ
  PLACE_KIND: 'place_kind', // 場所区分
  PLACE_KIND_SEARCH: 'place_kind_search', // 場所区分(検索条件)
  PLACE_LEVEL: 'place_level', // 場所区分
  PLACE_TYPE: 'place_type', // 場所纏め区分
  PLAN_FLAG: 'plan_flag', // 立案対象フラグ
  PLAN_KIND: 'plan_kind', // 立案区分
  PLAN_REQUEST_KIND: 'plan_request_kind', // 立案指示区分
  PLAN_STATUS: 'plan_status', // 立案状態
  PLANNING_EVALUATION_LOGIC: 'planning_evaluation_logic', // 配車評価ロジック
  PLANNING_ORDER_REFLECTION_FLAG: 'planning_order_reflection_flag', // 配車オーダ反映フラグ
  PLANNING_STATUS: 'planning_status', // 配車ステータス
  PNTCHD_KIND: 'pntchd_kind', // 親子フラグ
  PRELOAD_KIND: 'preload_kind', // 積置き指定フラグ
  PRELOADABLE: 'preloadable', // 積置きフラグ
  PUBLIC_FLG: 'public_flg', // ブロック外公開フラグ
  REAL_VEHICLE_ENTRY_STATUS: 'real_vehicle_entry_status', // 実車番登録ステータス
  REAL_VEHICLE_KIND: 'real_vehicle_kind', // トラック／トレーラ区分
  REAL_VEHICLE_KIND_SEARCH: 'real_vehicle_kind_search', // トラック／トレーラ区分検索条件
  REASON_KIND: 'reason_kind', // 辞退理由区分
  RECORD_WORK_KIND: 'record_work_kind', // 実績登録作業区分
  REFUSAL_CODE: 'refusal_code', // 依頼拒否理由コード
  RELATION_TYPE: 'relation_type', // 関連区分
  REMAND_CODE: 'remand_code', // 承認指し戻し理由コード
  SECTION_PARTITION_FLAG: 'section_partition_flag', // 区間分割フラグ
  SEND_FLG: 'send_flg', // OBIC連携済みフラグ
  STANDARD_KIND: 'standard_kind', // 基準荷姿区分
  STATUS: 'status', // 状態
  REQUEST_STATUS: 'request_status', // 状態（運送依頼のみ）
  STD_KIND: 'std_kind', // STD・OPT区分
  TARIFF_CSV_HEADER: 'tariff_csv_header', // csv_header
  TARIFF_TYPE: 'tariff_type', // タリフ区分
  TIME_ASSIGN_KIND: 'time_assign_kind', // 時間指定区分
  TIME_STRICT: 'time_strict', // 時間厳格度
  TOTALIZATION_TYPE: 'totalization_type', // 集計区分
  TRANSPORT_COMPLETE_STATUS: 'transport_complete_status', // 運送完了状態
  TRANSPORT_DISTANCE_KIND: 'transport_distance_kind', // 輸送距離区分
  TRANSPORT_TYPE_KIND: 'transport_type_kind', // 輸送形態区分
  UPDATE_FLAG: 'update_flag', // 更新フラグ
  USE_FLAG: 'use_flag', // 有効／無効
  USE_HIGHWAY: 'use_highway', // 高速利用有無
  VALID_FLAG: 'valid_flag', // 有効フラグ
  USER_TYPE: 'user_type', // ユーザ属性
  VEHICLE_CATEGORY: 'vehicle_category', // 車種
  VEHICLE_CONDITION: 'vehicle_condition', // 輸送手段条件
  VEHICLE_KIND: 'vehicle_kind', // 輸送手段区分
  VEHICLE_MODEL: 'vehicle_model', // 車型
  VEHICLE_OPERATION_KIND: 'vehicle_operation_kind', // 稼働／非稼働
  WARNING_KIND: 'warning_kind', // 警告区分
  WEEKDAY_NAME: 'weekday_name', // 曜日
  WORK_KIND: 'work_kind', // 作業区分
  WORKING_DAY: 'working_day', // 稼働日区分
  VEHICLE_GROUP: 'list_vehicle_group', // 車両パターン
  PLANNING_EVALUATION_PATTERN: 'list_planning_evaluation_pattern', // 平価パターン
  PLANNING_PATTERN: 'list_planning_pattern', // 計画パターン
  PROGRESS_STATUS: 'progress_status', // 進捗状態
  PROCESS_STATUS: 'process_status', // 処理状態
  BILLING_PERIOD_CODE: 'billing_period_code', // 請求画面検索期間
  PAYMENT_PERIOD_CODE: 'payment_period_code', // 支払画面検索期間
  SEND_BACK_COMMENT: 'send_back_comment', // 差し戻しコメント
  TAX_CATEGORY: 'tax_category', // 税区分
  SORT_ORDER: 'sort_order', // ソート条件
  AUTO_SECTION_DIVISIONS: 'auto_section_divisions', // 自動区間分割可否
  REGISTERING: 'registering', // 入力状態
  LOCK_FLAG: 'lock_flag', // ロックフラグ
  PAYMENT_TERMS: 'payment_terms', // 支払条件
}

export const DOMAIN_NAME = {
  FIELDS: 'result_fields',
  SEARCH_FIELDS: 'search_fields',
}

/**
 * カラムタイプ.
 * @constant
 * @type {Object}
 */
export const COLUMN_TYPE = {
  UNDEFINED: -1,
  VIEW: 1,
  LABEL: 2,
  COLUMN: 3,
  BUTTON: 4,
  TEXT: 5,
  LIST: 6,
  RADIOGROUP: 7,
  CHECKBOX: 8,
  CALENDAR: 9,
  GRID: 10,
  CALENDAR_SEARCH: 11,
  TEXTAREA: 12,
  PASSWORD: 13,
  TIME: 14,
  CHECKBOXGROUP: 15,
}

export const INPUT_TYPE_CHECK = {
  ONLY_NUMERIC: '1',
  ONLY_ALPHANUMERIC: '2',
  NUMERIC: '3',
  ALPHANUMERIC: '4',
  FULLCHAR: '5',
  FLOAT: '6',
  DATE: '7',
  TIME: '8',
  REQUIRED: '9',
  MAXLENGTH: '10',
  MINLENGTH: '11',
  ZIPNUMERIC: '12',
  DATETIMERANGE: '13',
  MAXVALUE: '14',
  MINVALUE: '15',
  DATERANGE: '16',
  ALPHANUMERIC_CODE: '17',
  NUMBER_POSITIVE_NEGATIVE: '18',
  EMAIL: '19',
  ONETONINE: '20',
}

export const MSG_TYPE = {
  E: 'ERROR',
  I: 'INFO',
  W: 'WARN',
}

export const DETAIL_SCREEN_KBN = {
  DETAIL: 0,
  NEW: 1,
  COPY: 2,
}

export const PROCESS_KIND = {
  INSERT: 'I', // 追加
  UPDATE: 'U', // 更新
  DELETE: 'D', // 削除
}

export const AREA_ENTRY_KIND = {
  PREFECTURE: '10', // 都道府県
  CITY: '20', // 市区町村
  POST: '30', // 郵便番号
  PLACE: '40', // 場所
}

export const IS_ONETIME_MASTER = {
  NORMAL: '0', // 通常マスタ
  TEMP: '1', // 一時登録マスタ
}

export const IS_DELETED = {
  NORMAL: '0', // 通常
  DELETED: '1', // 削除済
}

export const CHG_TCH_STATUS = {
  NASI: '0', // なし
  ZUMI: '1', // 通知済
  IRI: '2', // 通知要
}

export const PACKUP_FLAG = {
  NO: '0', // 無し
  YES: '1', // 有り
}

// 配車オーダーステータス
export const DELETE_STATUS = {
  NORMAL: '10', // 通常
  WAIT_FOR_UNDELETE: '20', // 解除待ち
  WAIT_FOR_DELETE: '30', // 削除待ち
  SASIKAE: '40', // 差替済み
}

export const PLANNING_STATUS = {
  NOT_ASSIGNED: '10', // 未配車
  ASSIGNED: '20', // 配車済
  COMPLETE: '30', // 確定
}

export const DATE_DIFF_PERIOD = {
  MAX: 5, // 最大開始・終了の差分
}

export const LIMITED_MAX_MIN = {
  MIN: 0,
  MAX: 999999999,
}

export const REAL_CAR_ENTRY_STATUS = {
  NOTREQUEST: '00', // 未依頼
  REQUEST: '10', // 依頼
  DECLINE: '20', // 辞退
  CONSENT: '30', // 承諾
  CANCEL: '40', // 取消
}
export const PERIOD = {
  DEPARTURE: '10', // 出荷日
  ARRIVAL: '20', // 搬入日
}
/** 登録区分 */
export const ENTRY_KIND = {
  UPDATE: '10', //更新
  INSERT: '20', // 新規
  DELETE: '30', //削除
  THROUGH: '40', // 登録対象外
}

/** 数量分割フラグ */
export const COUNT_PARTITION_FLAG = {
  NO: '0',
  YES: '1',
}
/** 区間分割フラグ */
export const SECTION_PARTITION_FLAG = {
  NO: '0',
  YES: '1',
}

/** 取消フラグ */
export const CANCEL_FLAG = {
  NORMAL: '0',
  CANCEL: '1',
}

/**
 * 制御タイプ.
 *
 * @constant
 * @type {Object}
 */
export const VISIBILITY = {
  UNDEFINED: -1,
  INVISIBLE: 0,
  VISIBLE: 1,
  DISABLE: 2,
}

/**
 * 配車パラメータ型
 */
export const PLANNING_PARAMETER_TYPE = {
  STRING: '10', // 文字列
  INTEGER: '20', // 数値(整数)
  DECIMAL: '30', // 数値(小数)
  SELECT: '40', // 選択
}

/**
 * 有効／無効
 */
export const USE_FLAG = {
  VALID: '10', // 有効
  INVALID: '20', // 無効
}

/**
 * 表示権限
 */
export const INFORMATION_RELEASE_LEVEL = {
  ALL: '10', // すべて
  ADMIN_ONLY: '20', // 管理者のみ
}

/**
 * 登録者区分
 */
export const ENTRY_USER_KIND = {
  USER: '0', // ユーザ
  SYSTEM: '1', // システム
}

/**
 * 部署区分
 */
export const DEPARTMENT_KIND = {
  OWNER: '10', // 荷主
  CONSIGNEE: '20', // 荷受者
  CARRIER: '30', // 運送会社
  DISTRIBUTOR: '40', // 特約店
  OWN_COMPANY: '50', // 自社
  ETC: '60', // その他
}

/**
 * サーバと定義合わせること（仕様がまだである。）
 * 輸送依頼（発注）の状態
 */
export const TRANSPORT_STATUS = {
  // 2 -> 10 発注差戻し
  // 4 -> 11 依頼拒否
  // 7 -> 12 輸送依頼期限切れ(cancel)
  // 13 -> 13 add:自動運賃計算済み
  // 14 -> 14 add:自動運賃計算失敗
  // 1 -> 20 発注確定
  // 3 -> 30 発注承認済
  // 5 -> 31 業者が依頼を確認（依頼確認）
  // 6 -> 40 依頼承諾
  // 7 -> 50 実車番登録済
  // 8 -> 51 輸送計画確定済
  APPROVE_BACK: '10', // 承認差し戻し
  REQUEST_REJECT: '11', // 承認拒否
  REQUEST_FIRED_CANCEL: '12', // 発注キャンセル
  AUTO_FARE_CALC_DONE: '13', // add:自動運賃計算済み
  AUTO_FARE_CALC_FAIL: '14', // add:自動運賃計算失敗
  APPROVE_WAIT: '20', // 承認待ち（依頼）
  APPROVE_DONE: '30', // 承認済み
  REQUEST_READ: '31', // 承認既読
  REQUEST_PERMIT: '40', // 承認承諾
  REQUEST_REALVEHICLE_CONFIRM: '50', // 承認承諾 & 実車番確定済み
  REQUEST_REALVEHICLE_FINISH: '51', // 承認承諾 & 輸送計画確定済
}

/**
 * 削除データ表示
 */
export const DELETED_KIND = {
  NOT_DELETED: '1', // 削除データ以外
  DELETED: '2', // 削除データ
}

/**
 * 車両種類(検索用)表示
 */
export const REAL_VEHICLE_KIND_SEARCH = {
  TRTC: '0', // ヘッドorトラクタ
  TL: '1', // シャーシorトレーラー
}

/**
 * INTEGER 最小値、最大値
 * @constant
 * @type {Object}
 */
export const INTEGER_MAX_MIN = {
  MAX: 999999999, // integer 4byte (-2,147,483,648 ~ +2,147,483,647 )
  MIN: 0, // min_value:0 効かない blurされる
  MINUS_MIN: -999999999,
}

export const GANTT_WORK_KIND = {
  DepoStart: '0', //出発
  Depo: '1', //荷積
  Drop: '2', //荷卸
  Move: '3', //実車走行
  MoveRest: '4', //実車走行（休憩有）
  MoveEmpty: '5', //空車走行
  MoveEmptyRest: '6', //空車走行（休憩有)
  MoveWait: '7', //待ち
  DepoEnd: '9', //到着
  Vehicle: 'A', //車両
  Trip: 'B', //ﾄﾘｯﾌﾟ
  Rest: 'C', //休憩
  LastDepo: 'D', //宵積み
}

export const GANTT_RECT_STYLE = {
  DepoStart: 'stroke:black; fill:#DDDDDD;', //出発
  Depo: 'stroke:black; fill:#CCFFCC;', //荷積
  Drop: 'stroke:black; fill:#CCFFFF;', //荷卸
  Move: 'stroke:black; fill:#008000;', //実車走行
  MoveRest: 'stroke:black; fill: #90EE90;', //実車走行（休憩有）
  MoveEmpty: 'stroke:black; fill: #0000FF;', //空車走行
  MoveEmptyRest: 'stroke:black; fill: #00FFFF;', //空車走行（休憩有)
  MoveWait: 'stroke:black; fill:#FFA500;', //待ち
  DepoEnd: 'stroke:black; fill:#DDDDDD;', //到着
  Rest: 'stroke:black; fill:#FFA500;', //休憩
  LastDepo: 'stroke:black; fill:#FFCCCC;', //宵積み
}

export const BOX_ITEM_KIND = {
  Vehicle: 'A', //車両
  Info: 'I', //車両
  Trip: 'B', //ﾄﾘｯﾌﾟ
  DepoStart: '0', //出発
  Depo: '1', //荷積
  Drop: '2', //荷卸
  DepoEnd: '9', //到着
  LastDepo: 'D', //宵積み
}

export const BOX_HEADER_STYLE = {
  Trip: 'background-color:#FFFF99', //ﾄﾘｯﾌﾟ
  DepoStart: 'background-color:#DDDDDD;color:black;', //出発
  Depo: 'background-color:#CCFFCC;color:black;', //荷積
  Drop: 'background-color:#CCFFFF;color:black;', //荷卸
  DepoEnd: 'background-color:#DDDDDD;color:black;', //到着
  LastDepo: 'background-color:#FFCCCC;color:black;', //宵積み
}

export const BOX_STATUS_STYLE = {
  Init: 'background-color:White', //未確定
  Fixed: 'background-color:Gold', //確定済
  WaitForUndelete: 'background-color:PaleGreen', //解除待
  WaitForDelete: 'background-color:DarkTurquoise;color:black;', //削除待
  Sasikae: 'background-color:SkyBlue', //差替済
  OptimisticLocked: 'background-color:LightGray', //排他制御
  VehicleDeleted: 'background-color:#D3D3D3', //車両削除
  NeedNotify: 'background-color:Pink', //通知要
}

export const BOX_EDIT_ERROR_LEVEL = {
  None: 0,
  Warning: 1,
  Error: 3,
}

export const NODE_FIX_STATUS = {
  Unfixed: 0, // 未確定
  Fixed: 1, // 確定済
  WaitForUndelete: 2, // 解除待
  WaitForDelete: 3, // 削除待
  Sasikae: 4, // 差替済
  OptimisticLocked: 5, // 排他制御
  NeedChangeNotice: 6, // 通知要
}

export const ROUTE_COLOR = [
  '#FF0000',
  '#00B0F0',
  '#FFFF00',
  '#00B050',
  '#FFC000',
  '#0070C0',
  '#C00000',
  '#7030A0',
  '#92D050',
  '#002060',
]

/**
 * 承諾・拒否（1:承諾、2:拒否）
 */
export const PERMIT_REFUSE = {
  PERMIT: '1', // 承諾
  REFUSE: '2', // 拒否
}

/**
 * 請求支払区分（０；請求、１:支払）
 */
export const COST_TYPE = {
  BILLING: '0', // 請求
  PAYMENT: '1', // 支払
}

/**
 * 往復区分（1:片道、2:往復、3:混在）
 */
export const ONEWAY_OR_ROUND = {
  ONEWAY: '1', // 片道 = 1
  ROUNDTRIP: '2', // 往復 = 2
  MIXROUND: '3', // 混在 = 3
}
/**
 * 往路復路区分（0:往路、1:復路）
 */
export const GO_OR_BACK = {
  GOWAY: '0', // 往路 = 0
  BACK_WAY: '1', // 復路 = 1
}

/**
 * 2台積区分（0:1台積、1:2台積）
 */
export const IS_MULTIPLE_LOADING = {
  LOAD_ONE: '0', // 1台積,
}
/**
 * Ganttの作業区分（0:出発、1:荷積、2:荷卸・・・）
 */
export const WORK_KIND = {
  DEPARTURE: '0', // 出発,
  LOAD: '1', // 荷積,
  UNLOAD: '2', // 荷積,
}
/**
 * 実績登録作業区分（1:積、2:卸）
 */
export const RECORD_WORK_KIND = {
  LOAD: '1', // 積,
  UNLOAD: '2', // 卸,
}
/**
 * 荷積荷卸作業の進捗状態表示（0:未実施、1:実施済）
 */
export const RECORD_PROGRESS_STATUS = {
  NOT_RUN: '0', // 未実施,
  RUN: '1', // 実施済,
}

/**
 * 請求支払ステータス（0:情報作成済み、1:インターロック解除、・・・）
 */
export const COST_STATUS = {
  CEREATED: '0',
  INTERLOCK_UNLOCK: '1', //インターロック解除
  CONFIRMED: '2', //確定済み
  REMEND: '3', //差戻
  APPROVED: '4', //承認済み
  UPDATE_LOCK: 'L', //更新ロック
}

/**
 * ユーザーロール
 */
export const USER_EROLE = {
  ADMIN: 'ADMIN',
  APPROVE: 'APPROVE',
  USER: 'USER',
  TRANSPORT: 'TRANSPORT',
  SIMULATION: 'SIMULATION',
  OWNER: 'OWNER',
}

/**
 * 基本的に削除行のグレイ設定
 * 配車一覧の色設定
 */
export const TABLE_OPTION = {
  DELETED_ROW_VARIANT: 'secondary', //gray = 削除
  EXCLUSIVE_ROW_VARIANT: 'secondary', //gray = 手動配車画面編集中（ガント編集用オーダ排他テーブルあり）
  SUCCESS_ROW_VARIANT: 'success ', //green = 「解除待ち」の場合、背景を緑色
  WARNING_ROW_VARIANT: 'warning', //orange = 「差替済」の場合、背景を紫色
  //DANGER_ROW_VARIANT: 'danger', //red =
  //INFO_ROW_VARIANT: 'info', //blue =
}

export const ROW_KBN = {
  NONE: 0,
  FIRST: 1,
  LAST: 2,
  MIDDLE: 3,
}
/**
 * 確定フラグ
 */
export const DECISION_FLAG = {
  INIT: '0', // 未確定,
  FIXED: '1', // 確定,
}
/**
 * ログ大区分
 */
export const LARGE_LOG = {
  OPE: '10', // 操作履歴 = 10
  BATCH: '20', // バッチ = 20
  TERMINAL: '30', // 通信 = 30
  LOGIN: '40', // ログイン = 40
  MASTER: '50', // マスタ = 50
}

/**
 * 運賃手入力区分
 */
export const MANUAL_INPUT = {
  AUTO: '0', // 自動運賃計算
  MANUAL: '1', // 手動運賃入力
}

/**
 * （場所マスタ）場所区分
 */
export const PLACE_KIND = {
  //CODE_10: '10', // 搬出
  //CODE_20: '20', // 搬入
  //CODE_30: '30', // 搬出・搬入
  //CODE_40: '40', // 車庫
  PREFECTURE: '50', // 都道府県
  CITY: '60', // 市区町村
  //CODE_70: '70', // IC
}

/**
 * （搬出・搬入）場所区分
 */
export const PLACE_LEVEL = {
  PREFECTURE: '10', // 都道府県
  CITY: '20', // 市区町村
  PLACE: '30', // 場所
}

/**
 * 自動配車対象外フラグ(0, 1)
 */
export const AUTO_PLANNING_EXCLUDE_FLAG = {
  COVERD: '0', // 0 : 対象
}

/**
 * 高速道路使用可否フラグ
 */
export const HIGHWAY_USE_YN_FLAG = {
  NOT_USED: '0', // 0 : 利用しない
  USED: '1', // 1 : 利用する
}

/**
 * 処理状態
 * 0：完了、11：受注明細未作成、21：配車オーダー未作成
 * 31：区間分割未実施、32：誘導オーダー未作成
 */
export const PROCESS_STATUS = {
  DONE: '0', // 0：完了
}

/**
 * シミュレーション取込データフラグ
 * シミュレーション取込データの場合1、それ以外0
 */
export const IS_SIMULATION_DATA = {
  ETC: '0', // それ以外0
}

/**
 * 削除可否区分
 */
export const DELETE_IMPOSSIBLE_KIND = {
  DELETEABLE: '0', // 0 : 削除可
  IMPOSSIBLE: '1', // 1 : 削除不可
}

/**
 * 輸送手段条件
 */
export const VEHICLE_CONDITION = {
  DISABLE: '0', // 0 : なし
  ENABLE: '1', // 1 : あり
}

/**
 * 許可証チェック要否区分
 */
export const NEED_PERMIT_CHECK = {
  NEED_NO: '0', // 0 : 不要
  NEED_YES: '1', // 1 : 必要
}

/**
 * 送信フラグ
 */
export const SEND_FLG = {
  NOT_SENDING: '0', //未送信
  SENDED: '1', //送信済み
}

export const SLOT_NAME = {
  MARGIN: 'margin',
  TAB1_TABLE_DOWN: 'tab1TableDown',
  TAB2_TABLE_DOWN: 'tab2TableDown',
  TAB3_TABLE_DOWN: 'tab3TableDown',
  TAB2_TABLE_UP: 'tab2TableUp',
}

/**
 *  入力状態
 */
export const REGISTERING = {
  DEFAULT: '0', //未設定
  EDITING: '1', //入力中
}

/**
 * ロックフラグ
 */
export const LOCK_FLAG = {
  NORMAL: '0', // 0：通常
  LOCK: '1', // 1：ロック
}

Vue.config.productionTip = false
Vue.prototype.SCREEN_ID = SCREEN_ID
Vue.prototype.MASTER_CODE = MASTER_CODE
Vue.prototype.DOMAIN_NAME = DOMAIN_NAME
Vue.prototype.COLUMN_TYPE = COLUMN_TYPE
Vue.prototype.MSG_TYPE = MSG_TYPE
Vue.prototype.INPUT_TYPE_CHECK = INPUT_TYPE_CHECK
Vue.prototype.DETAIL_SCREEN_KBN = DETAIL_SCREEN_KBN
Vue.prototype.PROCESS_KIND = PROCESS_KIND
Vue.prototype.AREA_ENTRY_KIND = AREA_ENTRY_KIND
Vue.prototype.VISIBILITY = VISIBILITY
Vue.prototype.IS_ONETIME_MASTER = IS_ONETIME_MASTER
Vue.prototype.IS_DELETED = IS_DELETED
Vue.prototype.CHG_TCH_STATUS = CHG_TCH_STATUS
Vue.prototype.PLANNING_STATUS = PLANNING_STATUS
Vue.prototype.DATE_DIFF_PERIOD = DATE_DIFF_PERIOD
Vue.prototype.LIMITED_MAX_MIN = LIMITED_MAX_MIN
Vue.prototype.REAL_CAR_ENTRY_STATUS = REAL_CAR_ENTRY_STATUS
Vue.prototype.PERIOD = PERIOD
Vue.prototype.ENTRY_KIND = ENTRY_KIND
Vue.prototype.COUNT_PARTITION_FLAG = COUNT_PARTITION_FLAG
Vue.prototype.PLANNING_PARAMETER_TYPE = PLANNING_PARAMETER_TYPE
Vue.prototype.USE_FLAG = USE_FLAG
Vue.prototype.INFORMATION_RELEASE_LEVEL = INFORMATION_RELEASE_LEVEL
Vue.prototype.ENTRY_USER_KIND = ENTRY_USER_KIND
Vue.prototype.DEPARTMENT_KIND = DEPARTMENT_KIND
Vue.prototype.DELETED_KIND = DELETED_KIND
Vue.prototype.GANTT_WORK_KIND = GANTT_WORK_KIND
Vue.prototype.GANTT_RECT_STYLE = GANTT_RECT_STYLE
Vue.prototype.BOX_ITEM_KIND = BOX_ITEM_KIND
Vue.prototype.BOX_HEADER_STYLE = BOX_HEADER_STYLE
Vue.prototype.BOX_EDIT_ERROR_LEVEL = BOX_EDIT_ERROR_LEVEL
Vue.prototype.NODE_FIX_STATUS = NODE_FIX_STATUS
Vue.prototype.PERMIT_REFUSE = PERMIT_REFUSE
Vue.prototype.COST_TYPE = COST_TYPE
Vue.prototype.TABLE_OPTION = TABLE_OPTION
Vue.prototype.DECISION_FLAG = DECISION_FLAG
Vue.prototype.LARGE_LOG = LARGE_LOG
Vue.prototype.MANUAL_INPUT = MANUAL_INPUT
Vue.prototype.PLACE_KIND = PLACE_KIND
Vue.prototype.PLACE_LEVEL = PLACE_LEVEL
