<template>
  <b-form-group
    v-if="columnInfo.visible()"
    class="form-group"
    data-class="form-group"
    label-size="less-margin"
    :label-for="columnInfo.label"
    v-bind="$attrs"
  >
    <template v-slot:label>
      <span> {{ columnInfo.label }}</span>
    </template>
    <slot />
  </b-form-group>
</template>

<script>
export default {
  name: 'BaseFormGroup',

  inheritAttrs: false,

  props: {
    /**
     * 定義情報.
     * @type {Object}
     * @requires
     */
    columnInfo: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.form-group {
  font-weight: bold;
}
</style>
