<template>
  <BaseFormGroup
    data-class="base-date-time-from-to-field"
    class="base-date-time-from-to-field"
    :columnInfo="dateFromColumnInfo"
    v-bind="$attrs"
  >
    <BaseDateTimeFromTo
      :dateFromValue.sync="dateFrom"
      :dateToValue.sync="dateTo"
      :timeFromValue.sync="timeFrom"
      :timeToValue.sync="timeTo"
      :dateFromColumnInfo="dateFromColumnInfo"
      :dateToColumnInfo="dateToColumnInfo"
      :timeFromColumnInfo="timeFromColumnInfo"
      :timeToColumnInfo="timeToColumnInfo"
      :error-visible="errorVisible"
      :dateFromRules="dateFromRules"
      :dateToRules="dateToRules"
      :timeFromRules="timeFromRules"
      :timeToRules="timeToRules"
      :rangeTarget="rangeTarget"
      :state="state"
      :locale="locale"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </BaseFormGroup>
</template>

<script>
export default {
  to: 'BaseDateFromToField',
  inheritAttrs: false,

  data() {
    return {
      dateFrom: this.dateFromValue,
      dateTo: this.dateToValue,
      timeFrom: this.timeFromValue,
      timeTo: this.timeToValue,
    }
  },

  watch: {
    dateFromValue() {
      this.dateFrom = this.dateFromValue
    },
    dateToValue() {
      this.dateTo = this.dateToValue
    },
    timeFromValue() {
      this.timeFrom = this.timeFromValue
    },
    timeToValue() {
      this.timeTo = this.timeToValue
    },
  },

  props: {
    dateFromValue: {
      type: String,
      default: '',
    },

    dateToValue: {
      type: String,
      default: '',
    },

    timeFromValue: {
      type: String,
      default: '',
    },

    timeToValue: {
      type: String,
      default: '',
    },

    locale: {
      type: String,
      default: 'ja',
    },

    /**
     * 定義情報.
     * @type {Object}
     * @requires
     */
    dateFromColumnInfo: {
      type: Object,
      required: true,
    },

    dateToColumnInfo: {
      type: Object,
      default: null,
    },

    timeFromColumnInfo: {
      type: Object,
      required: true,
    },

    timeToColumnInfo: {
      type: Object,
      default: null,
    },
    /**
     * バリデーションチェックの状態を表示するか.
     * @type {Boolean}
     */
    state: {
      type: Boolean,
      default: false,
    },

    /**
     * エラーメッセージを表示するか.
     * @type {Boolean}
     */
    errorVisible: {
      type: Boolean,
      default: true,
    },

    /**
     * 入力検証ルール.
     * @type {String}
     */
    dateFromRules: {
      type: Array,
      default: () => [],
    },
    /**
     * 入力検証ルール.
     * @type {String}
     */
    dateToRules: {
      type: Array,
      default: () => [],
    },
    /**
     * 入力検証ルール.
     * @type {String}
     */
    timeFromRules: {
      type: Array,
      default: () => [],
    },
    /**
     * 入力検証ルール.
     * @type {String}
     */
    timeToRules: {
      type: Array,
      default: () => [],
    },

    rangeTarget: {
      type: String,
      default: null,
    },
  },

  computed: {},
}
</script>
