/**
 * サーバーアクセス用サービス（認証処理用）.
 *
 * @file system/authentication/AuthenticationService.js
 * @author Baek.JH (SBK)
 * @copyright Hitach Industry & Control Solutions, Ltd. All rights reserved.
 */

import { createService, createAuthenticaionService } from '@/services/index'
import AzureAdAuthenticationService from '@/system/authentication/AzureAdAuthenticationService'

const BASE_URL = `${process.env.VUE_APP_API_URL}`
class AuthenticationService {
  /**
   * ログイン.
   *
   * @param {Object} loginRequest ログイン要求情報
   */
  async login(loginRequest) {
    const accessToken = await AzureAdAuthenticationService.acquireToken()
    const authentication = createService(BASE_URL, accessToken)

    return await authentication
      .post('/login', {
        userId: loginRequest.userId,
        password: loginRequest.password,
        language: loginRequest.language,
      })
      .then((response) => {
        console.log('responsedUser', response)
        return response.data
      })
  }
  /**
   * ブロック選択.
   *
   * @param {Object} selectBlockRequest ブロック選択要求情報
   */
  selectBlock(selectBlockRequest) {
    return createAuthenticaionService(BASE_URL)
      .put('/select-block', {
        blockCode: selectBlockRequest.blockCode,
      })
      .then((response) => {
        return response.data
      })
  }
  /**
   * 言語選択.
   *
   * @param {Object} selectLangRequest 言語選択要求情報
   */
  selectLang(selectLangRequest) {
    return createAuthenticaionService(BASE_URL)
      .put('/select-lang', {
        lang: selectLangRequest.lang,
      })
      .then((response) => {
        return response.data
      })
  }
  /**
   * ログアウト
   */
  logout() {
    return createAuthenticaionService(BASE_URL).post('/logout', null)
  }
}

export default new AuthenticationService()
