<template>
  <BaseModal
    dataClass="upload-modal"
    size="md"
    :title="columnInfos.csv_import.label"
    @after-close="afterClose"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:main>
      <BaseLabel :columnInfo="columnInfos.import_title" />
      <b-form-file v-model="file" ref="fileinput" />
    </template>
    <template v-slot:footer="{ cancel }">
      <!-- アップロードボタン -->
      <BaseButton
        variant="primary"
        icon="upload"
        :columnInfo="columnInfos.upload"
        @click="upload"
      />
      <!-- 戻るボタン -->
      <BaseButton
        variant="primary"
        icon="times-circle"
        :columnInfo="columnInfos.cancel"
        @click="cancel"
      />
    </template>
  </BaseModal>
</template>

<script>
export default {
  name: 'UploadModal',

  inheritAttrs: false,

  data() {
    return {
      file: null,
    }
  },

  props: {
    /**
     * 項目定義情報.
     * @type {Object}
     */
    columnInfos: {
      type: Object,
      required: true,
    },
  },

  methods: {
    /**
     * アップロード時の処理です.
     */
    upload() {
      this.$emit('upload', this.file)
    },

    /**
     * モーダルを閉じた後の処理です.
     */
    afterClose() {
      this.$refs.fileinput.reset()
      this.$emit('after-close')
    },
  },
}
</script>
