<template>
  <BaseFormGroup
    :columnInfo="dateColumnInfo"
    v-if="dateColumnInfo.visible()"
    v-bind="$attrs"
  >
    <BaseDateTime
      :dateValue.sync="_dateValue"
      :timeValue.sync="_timeValue"
      :dateColumnInfo="dateColumnInfo"
      :timeColumnInfo="timeColumnInfo"
      :error-visible="errorVisible"
      :dateRules="dateRules"
      :timeRules="timeRules"
      :state="state"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </BaseFormGroup>
</template>

<script>
export default {
  name: 'BaseDateTimeField',

  inheritAttrs: false,

  props: {
    dateValue: {
      type: [String, Number],
      default: '',
    },

    timeValue: {
      type: String,
      default: '',
    },

    /**
     * 定義情報.
     * @type {Object}
     * @requires
     */
    dateColumnInfo: {
      type: Object,
      required: true,
    },

    timeColumnInfo: {
      type: Object,
      default: null,
    },

    /**
     * バリデーションチェックの状態を表示するか.
     * @type {Boolean}
     */
    state: {
      type: Boolean,
      default: false,
    },

    /**
     * エラーメッセージを表示するか.
     * @type {Boolean}
     */
    errorVisible: {
      type: Boolean,
      default: true,
    },

    /**
     * 入力検証ルール.
     * @type {String}
     */
    dateRules: {
      type: Array,
      default: () => [],
    },
    /**
     * 入力検証ルール.
     * @type {String}
     */
    timeRules: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    _dateValue: {
      get() {
        return this.dateValue
      },
      set(v) {
        if (this.dateValue !== v) this.$emit('update:dateValue', v)
      },
    },
    _timeValue: {
      get() {
        return this.timeValue
      },
      set(v) {
        if (this.timeValue !== v) this.$emit('update:timeValue', v)
      },
    },
  },
}
</script>

<style scoped></style>
