import { render, staticRenderFns } from "./BaseCheckboxGroupField.vue?vue&type=template&id=159e18fe&scoped=true&"
import script from "./BaseCheckboxGroupField.vue?vue&type=script&lang=js&"
export * from "./BaseCheckboxGroupField.vue?vue&type=script&lang=js&"
import style0 from "./BaseCheckboxGroupField.vue?vue&type=style&index=0&id=159e18fe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "159e18fe",
  null
  
)

export default component.exports