<template>
  <b-nav-item-dropdown
    v-if="navGroup.columnInfo.visible() && displayable"
    data-class="nav-dropdown"
    :disabled="navGroup.columnInfo.disable() || disabled"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:button-content>
      <BaseIcon v-if="!!navGroup.icon" :name="navGroup.icon" />
      <slot name="button-content">
        {{ navGroup.columnInfo.label }}
      </slot>
    </template>
    <BaseNavDropdownItem
      v-for="(item, index) in navGroup.items"
      :key="index"
      :navItem="item"
    ></BaseNavDropdownItem>
  </b-nav-item-dropdown>
</template>

<script>
export default {
  name: 'BaseNavDropdown',

  inheritAttrs: false,

  props: {
    /**
     * ナビゲーショングループ.
     * @type {Object}
     */
    navGroup: {
      type: Object,
      required: true,
    },

    /**
     * 押下不可かどうか.
     * @type {Boolean}
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    /**
     * 表示可能かどうか.
     * ラベルかアイコンが指定されていれば表示可能とする.
     * @return {Boolean}
     */
    displayable() {
      return (
        (this.navGroup.columnInfo.label != null &&
          typeof this.navGroup.columnInfo.label === 'string' &&
          this.navGroup.columnInfo.label.trim() != '') ||
        (this.navGroup.icon != null &&
          typeof this.navGroup.icon === 'string' &&
          this.navGroup.icon.trim() != '')
      )
    },
  },
}
</script>
