<template>
  <BaseFormGroup
    data-class="base-date-input-field"
    class="base-date-input-field"
    :columnInfo="columnInfo"
    v-bind="$attrs"
  >
    <BaseDateInput
      v-model="_value"
      :columnInfo="columnInfo"
      :error-visible="errorVisible"
      :rules="rules"
      :state="state"
      v-bind="$attrs"
    />
  </BaseFormGroup>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'BaseDateInputField',

  inheritAttrs: false,

  props: {
    value: {
      type: [String, Number],
      default: '',
    },

    /**
     * 定義情報.
     * @type {Object}
     * @requires
     */
    columnInfo: {
      type: Object,
      required: true,
    },

    /**
     * バリデーションチェックの状態を表示するか.
     * @type {Boolean}
     */
    state: {
      type: Boolean,
      default: false,
    },

    /**
     * 入力検証ルール.
     * @type {String}
     */
    rules: {
      type: Array,
      default: () => [],
    },

    /**
     * エラーメッセージを表示するか.
     * @type {Boolean}
     */
    errorVisible: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    _value: {
      get() {
        return _.isNil(this.value) ? this.value : String(this.value)
      },

      set(v) {
        if (this.value !== v) this.$emit('input', v)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.base-date-input-field {
  margin: 0;
  padding: 0;
}
</style>
