<template>
  <b-input-group size="sm" v-if="fromColumnInfo.visible()">
    <template v-slot:prepend v-if="labelVisible">
      <b-input-group-text class="group-text"
        >{{ fromColumnInfo.label }}
        <span v-if="fromRules[0] == INPUT_TYPE_CHECK.REQUIRED">*</span>
      </b-input-group-text>
    </template>
    <BaseDateInput
      v-model="_fromValue"
      :columnInfo="fromColumnInfo"
      :error-visible="errorVisible"
      :rules="fromRules"
      :rangeTarget="rangeTarget"
      :state="state"
      :locale="locale"
      @input="$emit('inputFrom', _fromValue)"
    />
    {{ '～' }}
    <BaseDateInput
      v-model="_toValue"
      :columnInfo="toColumnInfo"
      :error-visible="errorVisible"
      :rules="toRules"
      :state="state"
      :locale="locale"
      @input="$emit('inputTo', _toValue)"
    />
  </b-input-group>
</template>

<script>
export default {
  to: 'BaseDateFromTo',
  inheritAttrs: false,
  props: {
    labelVisible: {
      type: Boolean,
      default: false,
    },

    fromValue: {
      type: [String, Number],
      default: '',
    },

    toValue: {
      type: String,
      default: '',
    },

    locale: {
      type: String,
      default: 'ja',
    },

    /**
     * 定義情報.
     * @type {Object}
     * @requires
     */
    fromColumnInfo: {
      type: Object,
      required: true,
    },

    toColumnInfo: {
      type: Object,
      default: null,
    },
    /**
     * バリデーションチェックの状態を表示するか.
     * @type {Boolean}
     */
    state: {
      type: Boolean,
      default: false,
    },

    /**
     * エラーメッセージを表示するか.
     * @type {Boolean}
     */
    errorVisible: {
      type: Boolean,
      default: true,
    },

    /**
     * 入力検証ルール.
     * @type {String}
     */
    fromRules: {
      type: Array,
      default: () => [],
    },
    /**
     * 入力検証ルール.
     * @type {String}
     */
    toRules: {
      type: Array,
      default: () => [],
    },

    rangeTarget: {
      type: String,
      default: null,
    },
  },
  computed: {
    _fromValue: {
      get() {
        return this.fromValue
      },
      set(v) {
        if (this.fromValue !== v) this.$emit('update:fromValue', v)
      },
    },
    _toValue: {
      get() {
        return this.toValue
      },
      set(v) {
        if (this.toValue !== v) this.$emit('update:toValue', v)
      },
    },
  },
}
</script>
