<template>
  <BaseFormGroup
    data-class="base-text-input-group-field"
    class="base-text-input-group-field"
    :columnInfo="columnInfo1"
    v-if="columnInfo1.visible()"
    v-bind="$attrs"
  >
    <BaseTextInputGroup
      :columnInfo1="columnInfo1"
      :columnInfo2="columnInfo2"
      :columnInfo3="columnInfo3"
      :columnInfo4="columnInfo4"
      :codeValue1.sync="id1"
      :codeValue2.sync="id2"
      :codeValue3.sync="id3"
      :codeValue4.sync="id4"
      :btnColumnInfo="btnColumnInfo"
      :rule1="rule1"
      :rule2="rule2"
      :rule3="rule3"
      :rule4="rule4"
      :error-visible="errorVisible"
      :state="state"
      @click="$emit('click')"
      v-bind="$attrs"
      v-on="$listeners"
    >
    </BaseTextInputGroup>
  </BaseFormGroup>
</template>
<script>
export default {
  name: 'BaseTextInputGroupField',

  inheritAttrs: false,

  props: {
    id1: {
      type: [String],
      default: '',
    },
    id2: {
      type: [String],
      default: '',
    },
    id3: {
      type: [String],
      default: '',
    },
    id4: {
      type: [String],
      default: '',
    },

    /**
     * 定義情報.
     * @type {Object}
     * @requires
     */
    columnInfo1: {
      type: Object,
      required: true,
    },

    /**
     * 定義情報.
     * @type {Object}
     * @requires
     */
    columnInfo2: {
      type: Object,
      required: true,
    },

    /**
     * 定義情報.
     * @type {Object}
     * @requires
     */
    columnInfo3: {
      type: Object,
      required: true,
    },

    /**
     * 定義情報.
     * @type {Object}
     * @requires
     */
    columnInfo4: {
      type: Object,
      required: true,
    },

    btnColumnInfo: {
      type: Object,
      default: null,
    },
    /**
     * バリデーションチェックの状態を表示するか.
     * @type {Boolean}
     */
    state: {
      type: Boolean,
      default: false,
    },

    /**
     * 入力検証ルール.
     * @type {String}
     */
    rule1: {
      type: Array,
      default: () => [],
    },
    /**
     * 入力検証ルール.
     * @type {String}
     */
    rule2: {
      type: Array,
      default: () => [],
    },
    /**
     * 入力検証ルール.
     * @type {String}
     */
    rule3: {
      type: Array,
      default: () => [],
    },
    /**
     * 入力検証ルール.
     * @type {String}
     */
    rule4: {
      type: Array,
      default: () => [],
    },

    /**
     * エラーメッセージを表示するか.
     * @type {Boolean}
     */
    errorVisible: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
