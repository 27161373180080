/**
 * サーバーアクセス用サービス（画面表示情報用）.
 *
 * @file system/init/InitService.js
 * @author Baek.JH (SBK)
 * @copyright Hitach Industry & Control Solutions, Ltd. All rights reserved.
 */

import { createService, createAuthenticaionService } from '@/services'

class InitService {
  /**
   * ログイン画面用の画面表示情報を取得します.
   */
  loadInitInfoForLogin() {
    return createService(`${process.env.VUE_APP_API_URL}init/login`)
      .get('/')
      .then((response) => {
        return response.data
      })
  }
  /**
   * 画面表示情報を取得します.
   */
  loadInitInfo() {
    return createAuthenticaionService(`${process.env.VUE_APP_API_URL}init/`)
      .get('/')
      .then((response) => {
        return response.data
      })
  }
}

export default new InitService()
