/**
 * 状態管理（画面表示情報用）.
 *
 * @file system/init/initStore.js
 * @author Baek.JH (SBK)
 * @copyright Hitach Industry & Control Solutions, Ltd. All rights reserved.
 */

import InitService from '@/system/init/InitService'
import ColumnInfo from '@/system/init/ColumnInfo'

const initialAllMessageInfos = sessionStorage.getItem('allMessageInfos')
  ? JSON.parse(sessionStorage.getItem('allMessageInfos') || '')
  : null
const initialLanguageInfos = sessionStorage.getItem('languageInfos')
  ? JSON.parse(sessionStorage.getItem('languageInfos') || '')
  : null
const initialLoginScreenInfos = sessionStorage.getItem('loginScreenInfos')
  ? JSON.parse(sessionStorage.getItem('loginScreenInfos') || '')
  : null

const initialMessages = sessionStorage.getItem('messages')
  ? JSON.parse(sessionStorage.getItem('messages') || '')
  : null
const initialCodeMasters = sessionStorage.getItem('codeMasters')
  ? JSON.parse(sessionStorage.getItem('codeMasters') || '')
  : null
const initialSystemManagements = sessionStorage.getItem('systemManagements')
  ? JSON.parse(sessionStorage.getItem('systemManagements') || '')
  : null
const initialScreens = sessionStorage.getItem('screens')
  ? JSON.parse(sessionStorage.getItem('screens') || '')
  : null

const initialState = {
  allMessageInfos: initialAllMessageInfos ? initialAllMessageInfos : null,
  languageInfos: initialLanguageInfos ? initialLanguageInfos : null,
  loginScreenInfos: initialLoginScreenInfos ? initialLoginScreenInfos : null,
  messages: initialMessages ? initialMessages : null,
  codeMasters: initialCodeMasters ? initialCodeMasters : null,
  systemManagements: initialSystemManagements ? initialSystemManagements : null,
  screens: initialScreens ? initialScreens : null,
  serviceLoading: false,
  noticeCount: 0,
}

export const init = {
  namespaced: true,
  state: initialState,
  getters: {
    /**
     * ログイン画面用画面表示情報のロード有無を取得します.
     *
     * @return {boolean} ロード有無
     */
    isInitInfoForLoginLoaded(state) {
      console.log('isInitInfoForLoginLoaded', state.loginScreenInfos)
      return !!state.loginScreenInfos
    },
    /**
     * 指定した言語、画面ID、ドメインの画面項目情報リストを取得します.
     *
     * ログイン画面は、未だログイン言語が決まってないので、このメソッドを使用します.
     *
     * @param {string} language 言語
     * @param {string} screenId 画面ID
     * @param {string} domainName ドメイン名
     *
     * @return {Object} 画面項目情報リスト
     */
    getScreenInfoForLogin: (state) => (language) => (screenId) => (
      domainName
    ) => {
      if (state.loginScreenInfos) {
        const screenInfo = state.loginScreenInfos.find(
          (it) => it.language === language
        )
        if (screenInfo) {
          const screen = screenInfo.screens.find(
            (it) => it.screenId === screenId
          )
          if (screen) {
            const domain = screen.domains.find(
              (it) => it.domainName === domainName
            )
            if (domain) {
              return domain.domainColumns
                .map((e) => ({
                  [e.domainColumnName]: new ColumnInfo(e),
                }))
                .reduce((l, r) => Object.assign(l, r), {})
            }
          }
        }
      }
      return null
    },
    /**
     * 指定した元号、メッセージID、引数のメッセージを取得します.
     *
     * ログイン画面は、未だログイン言語が決まってないので、このメソッドを使用します.
     * 該当メッセージが存在しない場合は、nullを返します.
     *
     * @param {string} language 言語
     * @param {string} messageId メッセージID
     * @param {...} args 引数
     *
     * @return {string} メッセージ
     */
    getMessageForLogin: (state) => (language) => (messageId, ...args) => {
      if (state.allMessageInfos) {
        const messageInfo = state.allMessageInfos.find(
          (it) => it.language === language
        )
        if (messageInfo) {
          let message = messageInfo.messages.find((it) => it.id === messageId)
          if (message) {
            let messageValue = message.msg1
            for (let item of args) {
              messageValue = messageValue.replace('%s' || '%d', item)
            }
            return messageValue
          }
        }
      }
      return null
    },
    /**
     * 指定した言語の言語情報リストを取得します.
     *
     * @param {string} language 言語
     *
     * @return {Object} 言語情報リスト
     */
    getLanguages: (state) => (language) => {
      if (state.languageInfos) {
        const languageInfo = state.languageInfos.find(
          (it) => it.language === language
        )
        if (languageInfo) {
          const codeMaster = languageInfo.codeMasters.find(
            (it) => it.masterCode === 'language'
          )
          if (codeMaster) {
            return codeMaster.codeItems.map((e) => ({
              ['value']: e.itemCode,
              ['text']: e.label,
            }))
          }
        }
      }
      return null
    },
    /**
     * 指定した画面ID、ドメインの画面項目情報リストを取得します.
     *
     * @param {string} screenId 画面ID
     * @param {string} domainName ドメイン名
     *
     * @return {Object} 画面項目情報リスト
     */
    getScreens: (state) => (screenId) => (domainName) => {
      if (state.screens) {
        const screen = state.screens.find((it) => it.screenId === screenId)
        if (screen) {
          const domain = screen.domains.find(
            (it) => it.domainName === domainName
          )
          if (domain) {
            return domain.domainColumns
              .map((e) => ({
                [e.domainColumnName]: new ColumnInfo(e),
              }))
              .reduce((l, r) => Object.assign(l, r), {})
          }
        }
      }
      return null
    },
    /**
     * 指定した画面IDの画面名を取得します.
     *
     * @param {string} screenId 画面ID
     *
     * @return {string} 画面名
     */
    getScreenName: (state) => (screenId) => {
      if (state.screens) {
        const screen = state.screens.find((it) => it.screenId === screenId)
        if (screen) {
          return screen.screenName
        }
      }
      return null
    },
    /**
     * 指定した画面ID、ドメインの一覧ヘッダ情報リストを取得します.
     *
     * @param {string} screenId 画面ID
     * @param {string} domainName ドメイン名
     *
     * @return {Object} 一覧ヘッダ情報リスト
     */
    getFields: (state) => (screenId) => (domainName) => {
      if (state.screens) {
        const screen = state.screens.find((it) => it.screenId === screenId)
        if (screen) {
          const domain = screen.domains.find(
            (it) => it.domainName === domainName
          )
          if (domain) {
            return domain.domainColumns
              .filter((e) => e.visibility !== 0)
              .map((e) => ({
                ['key']: e.domainColumnName,
                ['label']: e.label,
                ['sortable']: e.domainColumnName !== 'key1',
                ['thStyle']: {
                  whiteSpace: 'nowrap',
                  minWidth: `${e.width}px`,
                  width: `${e.width}px`,
                },
              }))
          }
        }
      }
      return null
    },
    /**
     * 指定したメッセージID、引数のメッセージを取得します.
     *
     * 該当メッセージが存在しない場合は、nullを返します.
     *
     * @param {string} messageId メッセージID
     * @param {...} args 引数
     *
     * @return {string} メッセージ
     */
    getMessage: (state) => (messageId, ...args) => {
      if (state.messages) {
        let message = state.messages.find((it) => it.id === messageId)
        if (message) {
          let messageValue = message.msg1
          for (let item of args) {
            messageValue = messageValue.replace('%s' || '%d', item)
          }
          return messageValue
        }
      }
      return null
    },
    /**
     * 指定したマスタコードのコードリストを取得します.
     *
     * {value: xx, text: xx} 形式のコードリストを返します.
     *
     * @param {string} masterCode マスタコード
     *
     * @return {Object} コードリスト
     */
    getMasterCode: (state) => (masterCode) => {
      if (state.codeMasters) {
        const codeMaster = state.codeMasters.find(
          (it) => it.masterCode === masterCode
        )
        if (codeMaster) {
          return codeMaster.codeItems.map((e) => ({
            ['value']: e.itemCode,
            ['text']: e.label,
          }))
        }
      }
      return null
    },
    /**
     * 指定したコードのシステム管理設定値を取得します.
     *
     * @param {string} classCode クラスコード
     * @param {string} managementCode 管理コード
     *
     * @return {Object} 設定値
     */
    getSystemManagement: (state) => (classCode) => (managementCode) => {
      if (state.systemManagements) {
        const systemManagement = state.systemManagements.find(
          (it) =>
            it.classCode === classCode && it.managementCode === managementCode
        )
        if (systemManagement) {
          return systemManagement.value
        }
      }
      return null
    },

    getServiceLoading: (state) => {
      return state.serviceLoading
    },

    getNoticeCount: (state) => {
      return state.noticeCount
    },
  },
  actions: {
    /**
     * ログイン画面用画面表示情報を取得します.
     *
     * サーバーから全言語分の、ログイン画面情報 / 言語情報 / メッセージ情報を取得し、
     * 状態管理に登録します.
     */
    loadInitInfoForLogin({ commit }) {
      return InitService.loadInitInfoForLogin().then(
        (initInfo) => {
          // ログイン画面情報
          sessionStorage.setItem(
            'loginScreenInfos',
            JSON.stringify(initInfo.loginScreenInfos)
          )
          commit('setLoginScreenInfos', initInfo.loginScreenInfos)

          // 言語情報
          sessionStorage.setItem(
            'languageInfos',
            JSON.stringify(initInfo.languageInfos)
          )
          commit('setLanguageInfos', initInfo.languageInfos)

          // メッセージ情報
          sessionStorage.setItem(
            'allMessageInfos',
            JSON.stringify(initInfo.messageInfos)
          )
          commit('setAllMessageInfos', initInfo.messageInfos)

          // システム管理情報
          sessionStorage.setItem(
            'systemManagements',
            JSON.stringify(initInfo.systemManagements)
          )
          commit('setSystemManagements', initInfo.systemManagements)

          return Promise.resolve(initInfo)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    /**
     * ログインした言語用の画面表示情報を取得します.
     *
     * サーバーから指定した言語用の、全画面分画面情報 / コード情報 / メッセージ情報を
     * 取得し、状態管理に登録します.
     *
     * @param {string} language 言語
     */
    loadInitInfo({ commit }, language) {
      return InitService.loadInitInfo().then(
        (initInfo) => {
          // 全画面分画面情報
          sessionStorage.setItem('screens', JSON.stringify(initInfo.screens))
          commit('setScreens', initInfo.screens)

          // コード情報
          sessionStorage.setItem(
            'codeMasters',
            JSON.stringify(initInfo.codeMasters)
          )
          commit('setCodeMasters', initInfo.codeMasters)

          // メッセージ情報
          const allMessageInfos = sessionStorage.getItem('allMessageInfos')
            ? JSON.parse(sessionStorage.getItem('allMessageInfos') || '')
            : null
          if (allMessageInfos) {
            const messageInfo = allMessageInfos.find(
              (it) => it.language === language
            )
            if (messageInfo) {
              const messages = messageInfo.messages
              sessionStorage.setItem('messages', JSON.stringify(messages))
              commit('setMessages', messages)
            }
          }

          return Promise.resolve(initInfo)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    },
    /**
     * 画面表示情報を状態管理から削除します.
     *
     * ログイン処理を行う際に状態管理に登録した画面表示情報を削除します.
     * ログアウト処理でこの処理を呼出します.
     */
    removeInitInfo({ commit }) {
      sessionStorage.removeItem('screens')
      commit('removeScreens')
      sessionStorage.removeItem('codeMasters')
      commit('removeCodeMasters')
    },
    /**
     * 指定言語用のメッセージ情報を状態管理に登録します.
     *
     * @param {string} language 言語
     */
    setMessageInfoByLanguage({ commit }, language) {
      const allMessageInfos = sessionStorage.getItem('allMessageInfos')
        ? JSON.parse(sessionStorage.getItem('allMessageInfos') || '')
        : null
      if (allMessageInfos) {
        const messageInfo = allMessageInfos.find(
          (it) => it.language === language
        )
        if (messageInfo) {
          const messages = messageInfo.messages
          sessionStorage.setItem('messages', JSON.stringify(messages))
          commit('setMessages', messages)
        }
      }
    },
    /**
     * サーバーへ問合せ開始のため、ローディングを設定する
     */
    setServiceLoading({ commit }) {
      commit('SET_SERVICE_LOADING')
    },
    /**
     * サーバーへ問合せ終了のため、ローディングを解除する
     */
    setServiceUnLoading({ commit }) {
      commit('SET_SERVICE_UNLOADING')
    },
    /**
     * インフォメーション件数を設定する
     * @param {*} count インフォメーション件数
     */
    setNoticeCount({ commit }, count) {
      commit('SET_NOTICE_COUNT', count)
    },
  },
  mutations: {
    setAllMessageInfos(state, messageInfos) {
      state.allMessageInfos = messageInfos
    },
    setLanguageInfos(state, languageInfos) {
      state.languageInfos = languageInfos
    },
    setLoginScreenInfos(state, loginScreenInfos) {
      state.loginScreenInfos = loginScreenInfos
    },

    setScreens(state, screens) {
      state.screens = screens
    },
    setCodeMasters(state, codeMasters) {
      state.codeMasters = codeMasters
    },
    setSystemManagements(state, systemManagements) {
      state.systemManagements = systemManagements
    },
    setMessages(state, messages) {
      state.messages = messages
    },
    removeScreens(state) {
      state.screens = null
    },
    removeCodeMasters(state) {
      state.codeMasters = null
    },
    SET_SERVICE_LOADING(state) {
      state.serviceLoading = true
    },
    SET_SERVICE_UNLOADING(state) {
      state.serviceLoading = false
    },
    SET_NOTICE_COUNT(state, count) {
      state.noticeCount = count
    },
  },
}
