<template>
  <ValidationObserver ref="base-tab-group">
    <b-card no-body>
      <b-tabs card>
        <b-tab
          v-for="(group, index) in _inputComponents"
          :key="index"
          :title="group.label"
          class="tab"
        >
          <p v-if="group.description">{{ group.description }}</p>

          <slot v-if="group.slot == 'tab2TableUp'" name="tab2TableUp" />

          <b-form-row
            v-for="(item, index) in group.inputComponents"
            :key="index"
          >
            <BaseDetailInputGroup
              v-if="item.type === 'text'"
              v-model="inputModel[item.id]"
              :columnInfo="item.columnInfo"
              :rules="item.rules"
              :maxLength="item.maxLength"
              :minLength="item.minLength"
              :maxValue="item.maxValue"
              :minValue="item.minValue"
              :columnType="COLUMN_TYPE.TEXT"
            />
            <BaseDetailInputGroup
              v-if="item.type === 'password'"
              v-model="inputModel[item.id]"
              :columnInfo="item.columnInfo"
              :rules="item.rules"
              :maxLength="item.maxLength"
              :minLength="item.minLength"
              :columnType="COLUMN_TYPE.PASSWORD"
            />
            <BaseDetailInputGroup
              v-if="item.type === 'select'"
              v-model="inputModel[item.id]"
              :columnInfo="item.columnInfo"
              :options="item.options"
              :rules="item.rules"
              :columnType="COLUMN_TYPE.LIST"
              @change="(obj) => item.onChangeCallback(obj)"
            />
            <BaseDetailInputGroup
              v-if="item.type === 'radiogroup'"
              v-model="inputModel[item.id]"
              :columnInfo="item.columnInfo"
              :options="item.options"
              :rules="item.rules"
              :columnType="COLUMN_TYPE.RADIOGROUP"
              @change="(obj) => item.onChangeCallback(obj)"
            />
            <BaseDetailInputGroup
              v-if="item.type === 'checkbox'"
              v-model="inputModel[item.id]"
              :columnInfo="item.columnInfo"
              :rules="item.rules"
              :columnType="COLUMN_TYPE.CHECKBOX"
            />
            <BaseDetailInputGroup
              v-if="item.type === 'checkboxgroup'"
              v-model="inputModel[item.id]"
              :columnInfo="item.columnInfo"
              :options="item.options"
              :rules="item.rules"
              :columnType="COLUMN_TYPE.CHECKBOXGROUP"
            />
            <BaseDetailInputGroup
              v-if="item.type === 'date'"
              v-model="inputModel[item.id]"
              :columnInfo="item.columnInfo"
              :rules="item.rules"
              :columnType="COLUMN_TYPE.CALENDAR"
            />
            <BaseDetailInputGroup
              v-if="item.type === 'time'"
              v-model="inputModel[item.id]"
              :columnInfo="item.columnInfo"
              :rules="item.rules"
              :columnType="COLUMN_TYPE.TIME"
            />
            <BaseReferenceCodeName
              v-if="item.type === 'referenceCodeName'"
              :labelVisible="true"
              :codeValue.sync="inputModel[item.codeId]"
              :nameValue.sync="inputModel[item.nameId]"
              :labelValue.sync="inputModel[item.labelId]"
              :codeColumnInfo="item.codeColumnInfo"
              :nameColumnInfo="item.nameColumnInfo"
              :labelColumnInfo="item.labelColumnInfo"
              :btnColumnInfo="item.btnColumnInfo"
              :btn2ColumnInfo="item.btn2ColumnInfo"
              :codeRules="item.codeRules"
              :nameRules="item.nameRules"
              @click="(obj) => item.onClickCallback(obj)"
              @click2="(obj) => item.onClickCallback2(obj)"
            />
            <BaseReferenceCodeName
              v-if="item.type === 'referenceCode'"
              :labelVisible="true"
              :codeValue.sync="inputModel[item.codeId]"
              :codeColumnInfo="item.codeColumnInfo"
              :labelValue2.sync="inputModel[item.labelId2]"
              :labelColumnInfo2="item.labelColumnInfo2"
              :btnColumnInfo="item.btnColumnInfo"
              :codeRules="item.codeRules"
              @click="(obj) => item.onClickCallback(obj)"
            />
            <BaseDateFromTo
              v-if="item.type === 'dateFromTo'"
              :labelVisible="true"
              :fromValue.sync="inputModel[item.fromId]"
              :toValue.sync="inputModel[item.toId]"
              :fromColumnInfo="item.fromColumnInfo"
              :toColumnInfo="item.toColumnInfo"
              :fromRules="item.fromRules"
              :toRules="item.toRules"
              :locale="item.locale"
            />
            <BaseTimeFromTo
              v-if="item.type === 'timeFromTo'"
              :labelVisible="true"
              :fromValue.sync="inputModel[item.fromId]"
              :toValue.sync="inputModel[item.toId]"
              :fromColumnInfo="item.fromColumnInfo"
              :toColumnInfo="item.toColumnInfo"
              :fromRules="item.fromRules"
              :toRules="item.toRules"
              :locale="item.locale"
            />
            <BaseTextInputGroup
              v-if="item.type === 'textGroup'"
              :labelVisible="true"
              :codeValue1.sync="inputModel[item.id1]"
              :codeValue2.sync="inputModel[item.id2]"
              :codeValue3.sync="inputModel[item.id3]"
              :codeValue4.sync="inputModel[item.id4]"
              :columnInfo1="item.columnInfo1"
              :columnInfo2="item.columnInfo2"
              :columnInfo3="item.columnInfo3"
              :columnInfo4="item.columnInfo4"
              :btnColumnInfo="item.btnColumnInfo"
              :rules="item.rules"
              @click="(obj) => item.onClickCallback(obj)"
            />
            <BaseDateTime
              v-if="item.type === 'dateTime'"
              :labelVisible="true"
              :dateValue.sync="inputModel[item.dateId]"
              :timeValue.sync="inputModel[item.timeId]"
              :dateColumnInfo="item.dateColumnInfo"
              :timeColumnInfo="item.timeColumnInfo"
              :dateRules="item.dateRules"
              :timeRules="item.timeRules"
            />
            <BaseDateAndTimeFromTo
              v-if="item.type === 'dateAndTimeFromTo'"
              :labelVisible="true"
              :dateValue.sync="inputModel[item.dateId]"
              :timeFromValue.sync="inputModel[item.timeFromId]"
              :timeToValue.sync="inputModel[item.timeToId]"
              :dateColumnInfo="item.dateColumnInfo"
              :timeFromColumnInfo="item.timeFromColumnInfo"
              :timeToColumnInfo="item.timeToColumnInfo"
              :dateRules="item.dateRules"
              :timeFromRules="item.timeFromRules"
              :timeToRules="item.timeToRules"
            />
          </b-form-row>

          <slot v-if="group.slot == 'margin'" name="margin" />
          <slot v-if="group.slot == 'tab1TableDown'" name="tab1TableDown" />
          <slot v-if="group.slot == 'tab2TableDown'" name="tab2TableDown" />
          <slot v-if="group.slot == 'tab3TableDown'" name="tab3TableDown" />
          <slot v-if="group.slot2 == 'tab2TableDown'" name="tab2TableDown" />
        </b-tab>
      </b-tabs>
    </b-card>
  </ValidationObserver>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'BaseTabGroup',
  inheritAttrs: false,
  props: {
    inputComponents: {
      type: Array,
      default: () => null,
    },
    inputModel: {
      type: Object,
      required: true,
    },
  },
  computed: {
    _inputComponents() {
      // 画面配列設定順番で詳細画面のコンポーネントが表示される
      return this.inputComponents
      // displayOrder順番で詳細画面のコンポーネントが表示される
      //return this.rebuildInputComponents(this.inputComponents)
    },
  },
  methods: {
    rebuildInputComponents(info) {
      info.forEach((tabGroup) => {
        tabGroup.inputComponents.forEach((item) => {
          let order = -1
          switch (item.type) {
            case 'text':
            case 'password':
            case 'select':
            case 'radiogroup':
            case 'checkbox':
            case 'checkboxgroup':
            case 'date':
            case 'time':
              order = item.columnInfo.displayOrder
              break
            case 'referenceCodeName':
            case 'referenceCode':
              order = item.codeColumnInfo.displayOrder
              break
            case 'dateFromTo':
            case 'timeFromTo':
              order = item.fromColumnInfo.displayOrder
              break
            case 'textGroup':
              order = item.columnInfo1.displayOrder
              break
            case 'dateTime':
            case 'dateAndTimeFromTo':
              order = item.dateColumnInfo.displayOrder
              break
          }
          item.displayOrder = order
        })
        //ex) return _.orderBy(this.users, ['name', 'last_login'], ['desc', 'asc']);
        tabGroup.inputComponents = _.orderBy(
          tabGroup.inputComponents,
          'displayOrder',
          'asc'
        )
      })

      return info
    },
  },
}
</script>

<style lang="scss" scoped>
.tab {
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
