<template>
  <validation-provider
    :rules="_rules"
    :name="columnInfo.label"
    v-slot="{ validated, touched, passed, errors }"
  >
    <b-form-timepicker
      v-model="_value"
      size="sm"
      :disabled="columnInfo.disable() || disabled"
      :id="columnInfo.domainColumnName"
      :name="columnInfo.domainColumnName"
      :locale="locale"
      :hide-header="hideHeader"
      now-button
      reset-button
    ></b-form-timepicker>
    <!-- class="mb-2" -->
    <span v-if="errorVisible" class="text-danger">
      {{ errors[0] }}
    </span>
  </validation-provider>
</template>

<script>
import { isNil } from 'lodash'

export default {
  name: 'BaseTimeInput',

  inheritAttrs: false,

  data() {
    return {
      hideHeader: false,
    }
  },

  props: {
    value: {
      type: String,
      default: '',
    },

    locale: {
      type: String,
      default: 'ja',
    },

    /**
     * 定義情報.
     * @type {Object}
     * @requires
     */
    columnInfo: {
      type: Object,
      required: true,
    },

    /**
     * 入力不可か.
     * @type {Boolean}
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * バリデーションチェックの状態を表示するか.
     * @type {Boolean}
     */
    state: {
      type: Boolean,
      default: false,
    },

    /**
     * 入力検証ルール.
     * @type {String}
     */
    rules: {
      type: Array,
      default: () => [],
    },

    /**
     * エラーメッセージを表示するか.
     * @type {Boolean}
     */
    errorVisible: {
      type: Boolean,
      default: true,
    },

    rangeTarget: {
      type: String,
      default: null,
    },
  },

  computed: {
    _value: {
      get() {
        if (!isNil(this.value) && this.value.length == 5) {
          return `${this.value}:00`
        } else if (!isNil(this.value) && this.value.length == 6) {
          return this.value.match(/\d{2}/g).join(':')
        }

        return this.value
      },

      set(v) {
        if (this.value !== v) {
          this.$emit('input', v)
        }
      },
    },

    /**
     * 入力検証ルール.
     * @return {String}
     */
    _rules() {
      let rules = ''
      this.rules.forEach((element) => {
        if (element == this.INPUT_TYPE_CHECK.REQUIRED) {
          rules += 'required|'
        }
        if (element == this.INPUT_TYPE_CHECK.DATETIMERANGE) {
          rules += `dateTimeRange:@${this.rangeTarget}|`
        }
      })
      return rules
    },
  },
}
</script>
