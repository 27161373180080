/**
 * ルータ.
 *
 * @file router/index.js
 * @author Kim.HY (SBK)
 * @copyright Hitach Industry & Control Solutions, Ltd. All rights reserved.
 */

import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import { SCREEN_ID } from '@/common/const.js'
import ManualPlanningService from '@/plan/manualPlanning/ManualPlanningService'
Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: SCREEN_ID.LOGIN,
      component: () =>
        import(
          /* webpackChunkName: "system" */ '@/system/authentication/LoginPage.vue'
        ),
      beforeEnter: async (to, from, next) => {
        // ログイン画面を表示前に、ログイン画面用画面表示情報を取得します。
        if (!store.getters['init/isInitInfoForLoginLoaded']) {
          await store.dispatch('init/loadInitInfoForLogin').then(
            () => {
              // 処理なし
            },
            (error) => {
              console.log(error)
            }
          )
        }
        next()
      },
    },
    {
      path: '/',
      redirect: '/login',
      component: () =>
        import(/* webpackChunkName: "system" */ '@/system/main/IndexPage.vue'),
      children: [
        {
          name: SCREEN_ID.DASHBOARD,
          path: 'home',
          component: () =>
            import(
              /* webpackChunkName: "system" */ '@/system/main/dashboard/Dashboard.vue'
            ),
          props: true,
        },
        {
          name: 'license',
          path: 'system/license',
          component: () =>
            import(
              /* webpackChunkName: "system" */ '@/system/main/License.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.DEPARTMENT_LIST,
          path: 'master/department',
          component: () =>
            import(
              /* webpackChunkName: "master" */ '@/master/department/DepartmentList.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.DEPARTMENT_DETAIL,
          path: 'master/department/detail',
          component: () =>
            import(
              /* webpackChunkName: "master" */ '@/master/department/DepartmentDetail.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.PLACE_LIST,
          path: 'master/place',
          component: () =>
            import(
              /* webpackChunkName: "master" */ '@/master/place/PlaceList.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.PLACE_DETAIL,
          path: 'master/place/detail',
          component: () =>
            import(
              /* webpackChunkName: "master" */ '@/master/place/PlaceDetail.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.VEHICLE_LIST,
          path: 'master/vehicle',
          component: () =>
            import(
              /* webpackChunkName: "master" */ '@/master/vehicle/VehicleList.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.VEHICLE_DETAIL,
          path: 'master/vehicle/detail',
          component: () =>
            import(
              /* webpackChunkName: "master" */ '@/master/vehicle/VehicleDetail.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.VEHICLE_INFLOW_IMPOSSIBLE,
          path: 'master/vehicle/inflow-impossible',
          component: () =>
            import(
              /* webpackChunkName: "master" */ '@/master/vehicle/InflowImpossible.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.RECEIVE_ORDER_LIST,
          path: 'order/receiveorder',
          component: () =>
            import(
              /* webpackChunkName: "order" */ '@/order/receiveorder/ReceiveOrderList.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.RECEIVE_ORDER_DETAIL,
          path: 'order/receiveorder/detail',
          component: () =>
            import(
              /* webpackChunkName: "order" */ '@/order/receiveorder/ReceiveOrderDetail.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.PLANNING_ORDER_LIST,
          path: 'plan/planningOrder',
          component: () =>
            import(
              /* webpackChunkName: "plan" */ '@/plan/planningOrder/PlanningOrderList.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.PLANNING_ORDER_DETAIL,
          path: 'plan/planningOrder/detail',
          component: () =>
            import(
              /* webpackChunkName: "plan" */ '@/plan/planningOrder/PlanningOrderDetail.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.PLANNING_PATTERN_LIST,
          path: 'plan/planning-pattern',
          component: () =>
            import(
              /* webpackChunkName: "plan" */ '@/plan/planningPattern/PlanningPatternList.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.PLANNING_PATTERN_DETAIL,
          path: 'plan/planning-pattern/detail',
          component: () =>
            import(
              /* webpackChunkName: "plan" */ '@/plan/planningPattern/PlanningPatternDetail.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.PLANNING_EVALUATION_LIST,
          path: 'plan/planningevaluation',
          component: () =>
            import(
              /* webpackChunkName: "plan" */ '@/plan/planningEvaluation/PlanningEvaluationList.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.PLANNING_EVALUATION_DETAIL,
          path: 'plan/planningevaluation/detail',
          component: () =>
            import(
              /* webpackChunkName: "plan" */ '@/plan/planningEvaluation/PlanningEvaluationDetail.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.VEHICLE_OPERATION_LIST,
          path: 'plan/vehicle-operation',
          component: () =>
            import(
              /* webpackChunkName: "plan" */ '@/plan/vehicleOperation/VehicleOperationList.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.VEHICLE_OPERATION_DETAIL,
          path: 'plan/vehicle-operation/detail',
          component: () =>
            import(
              /* webpackChunkName: "plan" */ '@/plan/vehicleOperation/VehicleOperationDetail.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.MANUAL_PLANNING_SEARCH,
          path: 'plan/manual-planning',
          component: () =>
            import(
              /* webpackChunkName: "plan" */ '@/plan/manualPlanning/ManualPlanningSearch.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.MANUAL_PLANNING_EDIT,
          path: 'plan/manual-planning/edit',
          component: () =>
            import(
              /* webpackChunkName: "plan" */ '@/plan/manualPlanning/ManualPlanningEdit.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.PLACE_ORDER_LIST,
          path: 'plan/place-order',
          component: () =>
            import(
              /* webpackChunkName: "plan" */ '@/plan/placeOrder/PlaceOrderList.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.PLACE_ORDER_DETAIL,
          path: 'plan/place-order/detail',
          component: () =>
            import(
              /* webpackChunkName: "plan" */ '@/plan/placeOrder/PlaceOrderDetail.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.WORK_RECORD_LIST,
          path: 'plan/work-record',
          component: () =>
            import(
              /* webpackChunkName: "plan" */ '@/plan/workRecord/WorkRecordList.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.WORK_RECORD_DETAIL,
          path: 'plan/work-record/detail',
          component: () =>
            import(
              /* webpackChunkName: "plan" */ '@/plan/workRecord/WorkRecordDetail.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.TRANSPORT_REQUEST_LIST,
          path: 'plan/transport-request',
          component: () =>
            import(
              /* webpackChunkName: "plan" */ '@/plan/transportRequest/TransportRequestList.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.TRANSPORT_REQUEST_DETAIL,
          path: 'plan/transport-request/detail',
          component: () =>
            import(
              /* webpackChunkName: "plan" */ '@/plan/transportRequest/TransportRequestDetail.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.BLOCK_SELECT,
          path: 'system/block-select',
          component: () =>
            import(
              /* webpackChunkName: "system" */ '@/system/authentication/BlockSelect.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.LANG_SELECT,
          path: 'system/lang-select',
          component: () =>
            import(
              /* webpackChunkName: "system" */ '@/system/authentication/LangSelect.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.PASSWORD_CHANGE,
          path: 'system/password-change',
          component: () =>
            import(
              /* webpackChunkName: "system" */ '@/system/user/PasswordChange.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.LOGMANAGEMENT_LIST,
          path: 'system/logmanagement',
          component: () =>
            import(
              /* webpackChunkName: "system" */ '@/system/logmanagement/LogManagementList.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.INFORMATION_LIST,
          path: 'system/information',
          component: () =>
            import(
              /* webpackChunkName: "system" */ '@/system/information/InformationList.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.INFORMATION_DETAIL,
          path: 'system/information',
          component: () =>
            import(
              /* webpackChunkName: "system" */ '@/system/information/InformationDetail.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.USER_LIST,
          path: 'system/user',
          component: () =>
            import(
              /* webpackChunkName: "system" */ '@/system/user/UserList.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.USER_DETAIL,
          path: 'system/user/detail',
          component: () =>
            import(
              /* webpackChunkName: "system" */ '@/system/user/UserDetail.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.VEHICLE_GROUP_LIST,
          path: 'master/vehiclegroup',
          component: () =>
            import(
              /* webpackChunkName: "master" */ '@/master/vehicleGroup/VehicleGroupList.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.VEHICLE_GROUP_DETAIL,
          path: 'master/vehiclegroup/detail',
          component: () =>
            import(
              /* webpackChunkName: "master" */ '@/master/vehicleGroup/VehicleGroupDetail.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.PRODUCT_LIST,
          path: 'master/product',
          component: () =>
            import(
              /* webpackChunkName: "master" */ '@/master/product/ProductList.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.PRODUCT_DETAIL,
          path: 'master/product/detail',
          component: () =>
            import(
              /* webpackChunkName: "master" */ '@/master/product/ProductDetail.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.OPERATION_TIME_PATTERN_LIST,
          path: 'master/operation-time-pattern',
          component: () =>
            import(
              /* webpackChunkName: "master" */ '@/master/operationTimePattern/OperationTimePatternList.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.OPERATION_TIME_PATTERN_DETAIL,
          path: 'master/operation-time-pattern/detail',
          component: () =>
            import(
              /* webpackChunkName: "master" */ '@/master/operationTimePattern/OperationTimePatternDetail.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.CALENDAR_LIST,
          path: 'master/calendar',
          component: () =>
            import(
              /* webpackChunkName: "master" */ '@/master/calendar/CalendarList.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.CALENDAR_DETAIL,
          path: 'master/calendar/detail',
          component: () =>
            import(
              /* webpackChunkName: "master" */ '@/master/calendar/CalendarDetail.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.AREA_LIST,
          path: 'master/area',
          component: () =>
            import(
              /* webpackChunkName: "master" */ '@/master/area/AreaList.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.AREA_DETAIL,
          path: 'master/area/detail',
          component: () =>
            import(
              /* webpackChunkName: "master" */ '@/master/area/AreaDetail.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.REGULAR_ORDER_LIST,
          path: 'order/regularorder',
          component: () =>
            import(
              /* webpackChunkName: "master" */ '@/order/regularorder/RegularOrderList.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.REGULAR_ORDER_DETAIL,
          path: 'order/regularorder/detail',
          component: () =>
            import(
              /* webpackChunkName: "master" */ '@/order/regularorder/RegularOrderDetail.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.PRODUCT_PATTERN_LIST,
          path: 'master/productPattern',
          component: () =>
            import(
              /* webpackChunkName: "master" */ '@/master/productPattern/ProductPatternList.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.PRODUCT_PATTERN_DETAIL,
          path: 'master/productPattern/detail',
          component: () =>
            import(
              /* webpackChunkName: "master" */ '@/master/productPattern/ProductPatternDetail.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.SECTION_DIVISION_LIST,
          path: 'master/sectionDivision',
          component: () =>
            import(
              /* webpackChunkName: "master" */ '@/master/sectionDivision/SectionDivisionList.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.SECTION_DIVISION_DETAIL,
          path: 'master/sectionDivision/detail',
          component: () =>
            import(
              /* webpackChunkName: "master" */ '@/master/sectionDivision/SectionDivisionDetail.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.REAL_VEHICLE_LIST,
          path: 'master/realVehicle',
          component: () =>
            import(
              /* webpackChunkName: "master" */ '@/master/realVehicle/RealVehicleList.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.REAL_VEHICLE_DETAIL,
          path: 'master/realVehicle/detail',
          component: () =>
            import(
              /* webpackChunkName: "master" */ '@/master/realVehicle/RealVehicleDetail.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.TARIFF_LIST,
          path: 'master/tariff',
          component: () =>
            import(
              /* webpackChunkName: "master" */ '@/master/tariff/TariffList.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.TARIFF_DETAIL,
          path: 'master/tariff/detail',
          component: () =>
            import(
              /* webpackChunkName: "master" */ '@/master/tariff/TariffDetail.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.PASSAGE_PERMIT_LIST,
          path: 'master/passagePermit',
          component: () =>
            import(
              /* webpackChunkName: "master" */ '@/master/passagePermit/PassagePermitList.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.PASSAGE_PERMIT_DETAIL,
          path: 'master/passagePermit/detail',
          component: () =>
            import(
              /* webpackChunkName: "master" */ '@/master/passagePermit/PassagePermitDetail.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.FREIGHT_ITEM_LIST,
          path: 'master/freightItem',
          component: () =>
            import(
              /* webpackChunkName: "master" */ '@/master/freightItem/FreightItemList.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.FREIGHT_ITEM_DETAIL,
          path: 'master/freightItem/detail',
          component: () =>
            import(
              /* webpackChunkName: "master" */ '@/master/freightItem/FreightItemDetail.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.BILLING_LIST,
          path: 'accounting/billing',
          component: () =>
            import(
              /* webpackChunkName: "accounting" */ '@/accounting/billing/BillingList.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.BILLING_DETAIL,
          path: 'accounting/billing/detail',
          component: () =>
            import(
              /* webpackChunkName: "accounting" */ '@/accounting/billing/BillingDetail.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.SIMULATION_DATA_GENERATION,
          path: 'simulation/simulationDataGeneration',
          component: () =>
            import(
              /* webpackChunkName: "simulation" */ '@/simulation/simulationDataGeneration/SimulationDataGeneration.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.FARE_LIST,
          path: 'accounting/fare',
          component: () =>
            import(
              /* webpackChunkName: "accounting" */ '@/accounting/fare/FareList.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.PAYMENT_LIST,
          path: 'accounting/payment',
          component: () =>
            import(
              /* webpackChunkName: "accounting" */ '@/accounting/payment/PaymentList.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.PAYMENT_DETAIL,
          path: 'accounting/payment/detail',
          component: () =>
            import(
              /* webpackChunkName: "accounting" */ '@/accounting/payment/PaymentDetail.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.AUTO_PLANNING_LIST,
          path: 'plan/auto-planning',
          component: () =>
            import(
              /* webpackChunkName: "plan" */ '@/plan/autoPlanning/AutoPlanningList.vue'
            ),
          props: true,
        },
        {
          name: SCREEN_ID.AUTO_PLANNING_DETAIL,
          path: 'plan/auto-planning/detail',
          component: () =>
            import(
              /* webpackChunkName: "plan" */ '@/plan/autoPlanning/AutoPlanningDetail.vue'
            ),
          props: true,
        },
      ],
    },
  ],
})

export default router

router.beforeEach(async (to, from, next) => {
  // 認証が必要な画面且つ、ログイン情報が存在しない場合、
  // ログイン画面に遷移します。
  const publicPages = [SCREEN_ID.LOGIN]
  const authRequired = !publicPages.includes(to.name)
  const loggedIn = sessionStorage.getItem('user')

  if (authRequired && !loggedIn) {
    next({ name: SCREEN_ID.LOGIN, params: { redir: to.name } })
  } else {
    if (loggedIn && from.name === SCREEN_ID.MANUAL_PLANNING_EDIT) {
      let ganttRequestId = sessionStorage.getItem('ganttRequestId')
      // 手動配車編集画面を閉じる際に排他制御クリア処理を行う。
      if (ganttRequestId) {
        ManualPlanningService.closeList(ganttRequestId)
          .then(
            () => {
              // 処理なし
            },
            (e) => {
              console.log(e.response.data)
            }
          )
          .finally(() => {
            sessionStorage.setItem('ganttRequestId', null)
            next()
          })
      } else {
        next()
      }
    } else {
      next()
    }
  }
})
