export const search = {
  namespaced: true,
  state: () => ({
    searchCondition: {},
    selected: {},
  }),
  getters: {
    getSearchCondition: (state) => (viewId) => {
      return state.searchCondition[viewId]
    },
    getSelected: (state) => (viewId) => {
      return state.selected[viewId]
    },
  },
  actions: {
    setSearchCondition({ commit }, payload) {
      commit('SET_SEARCH_CONDITION', payload)
    },

    setSelected({ commit }, payload) {
      commit('SET_SELECTED', payload)
    },
  },
  mutations: {
    SET_SEARCH_CONDITION(state, payload) {
      state.searchCondition[payload.viewId] = payload.params
    },
    SET_SELECTED(state, payload) {
      state.selected[payload.viewId] = payload.value
    },
  },
}
