<template>
  <div data-class="base-label">
    <span v-if="columnInfo.visible()">
      {{ columnInfo.label }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'BaseLabel',

  props: {
    /**
     * 定義情報.
     * @type {Object}
     */
    columnInfo: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style></style>
