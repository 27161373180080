<template>
  <BaseFormGroup
    data-class="base-reference-code-name-field"
    class="base-reference-code-name-field"
    :columnInfo="codeColumnInfo"
    v-if="codeColumnInfo.visible()"
    v-bind="$attrs"
  >
    <BaseReferenceCodeName
      :codeValue.sync="_codeValue"
      :nameValue.sync="_nameValue"
      :labelValue="_labelValue"
      :codeColumnInfo="codeColumnInfo"
      :nameColumnInfo="nameColumnInfo"
      :labelColumnInfo="labelColumnInfo"
      :labelColumnInfo2="labelColumnInfo2"
      :btnColumnInfo="btnColumnInfo"
      :btn2ColumnInfo="btn2ColumnInfo"
      :error-visible="errorVisible"
      :codeRules="codeRules"
      :nameRules="nameRules"
      :codeMaxLength="codeMaxLength"
      :codeMinLength="codeMinLength"
      :nameMaxLength="nameMaxLength"
      :nameMinLength="nameMinLength"
      :state="state"
      @click="$emit('click')"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </BaseFormGroup>
</template>

<script>
export default {
  name: 'BaseReferenceCodeNameField',

  inheritAttrs: false,

  props: {
    codeValue: {
      type: [String, Number],
      default: '',
    },

    nameValue: {
      type: String,
      default: '',
    },

    labelValue: {
      type: String,
      default: '',
    },
    /**
     * 定義情報.
     * @type {Object}
     * @requires
     */
    codeColumnInfo: {
      type: Object,
      required: true,
    },

    nameColumnInfo: {
      type: Object,
      default: null,
    },
    labelColumnInfo: {
      type: Object,
      default: null,
    },
    labelColumnInfo2: {
      type: Object,
      default: null,
    },
    btnColumnInfo: {
      type: Object,
      required: true,
    },

    btn2ColumnInfo: {
      type: Object,
      required: false,
    },

    /**
     * バリデーションチェックの状態を表示するか.
     * @type {Boolean}
     */
    state: {
      type: Boolean,
      default: false,
    },

    /**
     * エラーメッセージを表示するか.
     * @type {Boolean}
     */
    errorVisible: {
      type: Boolean,
      default: true,
    },

    /**
     * 入力検証ルール.
     * @type {String}
     */
    codeRules: {
      type: Array,
      default: () => [],
    },
    /**
     * 入力検証ルール.
     * @type {String}
     */
    nameRules: {
      type: Array,
      default: () => [],
    },

    codeMaxLength: {
      type: [String, Number],
    },

    codeMinLength: {
      type: [String, Number],
    },

    nameMaxLength: {
      type: [String, Number],
    },

    nameMinLength: {
      type: [String, Number],
    },
  },
  computed: {
    _codeValue: {
      get() {
        return this.codeValue
      },
    },
    _nameValue: {
      get() {
        return this.nameValue
      },
    },
    _labelValue: {
      get() {
        return this.labelValue
      },
    },
  },
}
</script>

<style scoped></style>
