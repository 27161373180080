<template>
  <div data-class="paging-table" style="border">
    <BaseScrollableTable
      ref="paging-table"
      id="paging-table"
      class="paging-table"
      table-class="text-nowrap"
      :height="height"
      :fields="fields"
      :items="items"
      :perPage="perPageXXX"
      :screenId="screenId"
      :selectMode="selectMode"
      :currentPage="currentPage"
      @row-selected="onRowSelected"
      @clickVehicleOperationCell="clickVehicleOperationCell"
    />
    <div class="parent">
      <div class="child">
        <!-- 最大取得件数 -->
        <BaseSelectField
          v-model="_value"
          :columnInfo="columnInfoSize"
          :options="optionsSize"
          @change="changeSize($event)"
        />
      </div>
      <div class="child">
        <BasePagination
          v-model="currentPage"
          size="sm"
          align="center"
          class="child"
          :totalRows="totalRows"
          :perPage="perPageXXX"
          aria-controls="paging-table"
        />
      </div>
      <div class="child">
        <!-- １ページの表示件数 -->
        <BaseSelectField
          v-model="perPageXXX"
          align="right"
          :columnInfo="columnInfoPage"
          :options="optionsPage"
          @change="changeHyojikensu($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BasePagingTable',

  props: {
    /**
     * 列定義リスト.
     * @type {Array}
     */
    fields: {
      type: Array,
      required: true,
    },

    /**
     * 行リスト.
     * @type {Array}
     */
    items: {
      type: Array,
      default: () => [],
    },

    /**
     * 選択行リスト.
     * @type {Array}
     */
    selectedItems: {
      type: Array,
      default: () => [],
    },

    /**
     * 選択モード.
     * @type {String}
     */
    selectMode: {
      type: String,
      default: 'range',
    },

    /**
     * デフォルトの高さ.
     * @type {String}
     */
    height: {
      type: String,
      default: '100px',
    },

    /**
     * コンボボックスのオプション.
     * @type {Array}
     */
    optionsPage: {
      type: Array,
      default: () => [],
    },

    /**
     * コンボボックスのオプション.
     * @type {Array}
     */
    optionsSize: {
      type: Array,
      default: () => [],
    },

    /**
     * 項目定義情報.
     * @type {Object}
     */
    columnInfoPage: {
      type: Object,
      required: true,
    },

    /**
     * 項目定義情報.
     * @type {Object}
     */
    columnInfoSize: {
      type: Object,
      required: true,
    },

    value: {
      type: [Number, String],
      default: 500,
    },

    screenId: {
      type: String,
    },

    perPage: {
      type: String, // Code
      default: '10',
    },
  },

  data() {
    return {
      /**
       * 表示中のページ番号.
       * @type {Number}
       */
      currentPage: 1,
      /**
       * 1ページ毎に表示する行
       */
      perPageXXX: Number(this.perPage),
    }
  },

  computed: {
    /**
     * 合計行.
     * @return {Number}
     */
    totalRows() {
      return this.items.length
    },

    _value: {
      get() {
        return this.value
      },

      set(v) {
        if (this.value !== v) this.$emit('input', v)
      },
    },
  },

  methods: {
    /**
     * 行選択時に発火します.
     * @param {Array} items 選択行リスト.
     */
    onRowSelected(selectedItems) {
      this.$emit('update:selectedItems', selectedItems)
      this.$emit('row-selected', selectedItems)
    },
    // 「１ページの表示件数」ボタンクリック
    changeHyojikensu($event) {
      var num = Number($event)
      this.perPageXXX = num
      this.$emit('per-page-changed', $event)
    },

    changeSize($event) {
      this.$emit('size-changed', $event)
    },

    clickVehicleOperationCell(rowItem, isKind) {
      this.$emit('clickVehicleOperationCell', rowItem, isKind)
    },
  },
}
</script>

<style lang="scss" scoped>
.paging-table {
  font-size: 0.9rem;
  border: 1px solid rgb(221, 221, 221);
}
.parent {
  display: flex;
  align-items: center;
}
.child {
  flex: 1;
}
</style>
