<template>
  <b-input-group size="sm" v-if="codeColumnInfo.visible()">
    <template v-slot:prepend v-if="labelVisible">
      <b-input-group-text class="group-text"
        >{{ codeColumnInfo.label }}
        <span v-if="codeRules[0] == INPUT_TYPE_CHECK.REQUIRED">*</span>
      </b-input-group-text>
    </template>
    <BaseTextInput
      :class="_codeClass"
      v-model="_codeValue"
      :columnInfo="codeColumnInfo"
      :error-visible="errorVisible"
      :rules="codeRules"
      :maxLength="codeMaxLength"
      :minLength="codeMinLength"
      :state="state"
    />
    <BaseTextInput
      :class="_nameClass"
      v-if="nameColumnInfo != null"
      v-model="_nameValue"
      :columnInfo="nameColumnInfo"
      :error-visible="errorVisible"
      :rules="nameRules"
      :maxLength="nameMaxLength"
      :minLength="nameMinLength"
      :state="state"
    />
    <BaseTextInput
      class="ref-label1-input"
      v-if="labelColumnInfo != null"
      v-model="_labelValue"
      :columnInfo="labelColumnInfo"
    >
    </BaseTextInput>
    <!-- <b-input-group-append> -->
    <!-- </b-input-group-append> -->
    <BaseButton
      v-if="btnColumnInfo != null"
      :columnInfo="btnColumnInfo"
      @click="$emit('click')"
    />
    <BaseButton
      class="btn-border ref-btn2-input"
      v-if="btn2ColumnInfo != null"
      :columnInfo="btn2ColumnInfo"
      @click="$emit('click2')"
    />
    <BaseTextInput
      class="ref-label2-input"
      v-if="labelColumnInfo2 != null"
      v-model="_labelValue2"
      :columnInfo="labelColumnInfo2"
    >
    </BaseTextInput>
  </b-input-group>
</template>

<script>
export default {
  name: 'BaseReferenceCodeName',
  inheritAttrs: false,
  props: {
    labelVisible: {
      type: Boolean,
      default: false,
    },

    codeValue: {
      type: [String, Number],
      default: '',
    },

    nameValue: {
      type: String,
      default: '',
    },

    labelValue: {
      type: [String, Number],
      default: '',
    },
    labelValue2: {
      type: [String, Number],
      default: '',
    },
    /**
     * 定義情報.
     * @type {Object}
     * @requires
     */
    codeColumnInfo: {
      type: Object,
      required: true,
    },

    nameColumnInfo: {
      type: Object,
      default: null,
    },

    labelColumnInfo: {
      type: Object,
      default: null,
    },
    labelColumnInfo2: {
      type: Object,
      default: null,
    },

    btnColumnInfo: {
      type: Object,
    },
    btn2ColumnInfo: {
      type: Object,
      default: null,
    },
    /**
     * バリデーションチェックの状態を表示するか.
     * @type {Boolean}
     */
    state: {
      type: Boolean,
      default: false,
    },

    /**
     * エラーメッセージを表示するか.
     * @type {Boolean}
     */
    errorVisible: {
      type: Boolean,
      default: true,
    },

    /**
     * 入力検証ルール.
     * @type {String}
     */
    codeRules: {
      type: Array,
      default: () => [],
    },
    /**
     * 入力検証ルール.
     * @type {String}
     */
    nameRules: {
      type: Array,
      default: () => [],
    },

    codeMaxLength: {
      type: [String, Number],
    },

    codeMinLength: {
      type: [String, Number],
    },

    nameMaxLength: {
      type: [String, Number],
    },

    nameMinLength: {
      type: [String, Number],
    },
  },
  computed: {
    _codeValue: {
      get() {
        return this.codeValue
      },
      set(v) {
        if (this.codeValue !== v) this.$emit('update:codeValue', v)
      },
    },
    _nameValue: {
      get() {
        return this.nameValue
      },
      set(v) {
        if (this.nameValue !== v) this.$emit('update:nameValue', v)
      },
    },
    _labelValue: {
      get() {
        return this.labelValue
      },
      set(v) {
        if (this.labelValue !== v) this.$emit('update:labelValue', v)
      },
    },
    _labelValue2: {
      get() {
        return this.labelValue2
      },
      set(v) {
        if (this.labelValue2 !== v) this.$emit('update:labelValue2', v)
      },
    },
    _codeClass() {
      let newClass = 'ref-code-input-1'
      if (this.labelVisible == true) {
        if (
          this.codeColumnInfo != null &&
          this.nameColumnInfo == null &&
          this.labelColumnInfo2 == null
        ) {
          newClass = 'ref-code-input-2'
        } else if (
          this.codeColumnInfo != null &&
          this.nameColumnInfo == null &&
          this.labelColumnInfo2 != null
        ) {
          newClass = 'ref-code-input-3'
        } else {
          newClass = 'ref-code-input-1'
        }
      } else {
        if (
          this.codeColumnInfo != null &&
          this.nameColumnInfo == null &&
          this.labelColumnInfo2 == null
        ) {
          newClass = 'ref-code-input-field-2'
        } else if (
          this.codeColumnInfo != null &&
          this.nameColumnInfo == null &&
          this.labelColumnInfo2 != null
        ) {
          newClass = 'ref-code-input-field-3'
        } else {
          newClass = 'ref-code-input-field-1'
        }
      }
      return newClass
    },
    _nameClass() {
      let newClass = 'ref-name-input-1'
      if (this.labelVisible == true) {
        if (
          this.labelColumnInfo != null &&
          this.btn2ColumnInfo != null &&
          this.labelColumnInfo2 == null
        ) {
          newClass = 'ref-name-input-2'
        } else if (
          this.btnColumnInfo == null &&
          this.labelColumnInfo == null &&
          this.btn2ColumnInfo == null &&
          this.labelColumnInfo2 == null
        ) {
          newClass = 'ref-name-input-3'
        } else {
          newClass = 'ref-name-input-1'
        }
      } else {
        if (
          this.btnColumnInfo != null &&
          this.labelColumnInfo != null &&
          this.btn2ColumnInfo != null &&
          this.labelColumnInfo2 == null
        ) {
          newClass = 'ref-name-input-field-2'
        } else if (
          (this.btnColumnInfo == null ||
            this.btnColumnInfo.visible() == false) &&
          this.labelColumnInfo == null &&
          this.btn2ColumnInfo == null &&
          this.labelColumnInfo2 == null
        ) {
          newClass = 'ref-name-input-field-3'
        } else {
          newClass = 'ref-name-input-field-1'
        }
      }
      return newClass
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-border {
  border-left: 1px white solid;
}

$detail-ref-code: 100px;
$detail-ref-btn1: 31px;
$detail-ref-btn2: 80px;
$detail-ref-label1: 120px;
$detail-ref-label2: 200px;
$detail-ref-name-min-w: 100px;

.ref-label1-input {
  width: $detail-ref-label1;
}
.ref-btn2-input {
  width: $detail-ref-btn2;
}
.ref-label2-input {
  width: $detail-ref-label2;
}
//--------------------------------------------------
.ref-code-input-1 {
  width: $detail-ref-code;
}
.ref-code-input-2 {
  width: calc(100% - #{$detail-ref-btn1} - #{$detail-group-text-label});
}
.ref-code-input-3 {
  width: calc(
    100% - #{$detail-ref-btn1} - #{$detail-ref-label2} - #{$detail-group-text-label}
  );
}

.ref-name-input-1 {
  min-width: $detail-ref-name-min-w;
  width: calc(
    100% - #{$detail-ref-code} - #{$detail-ref-btn1} - #{$detail-group-text-label}
  );
}
.ref-name-input-2 {
  min-width: $detail-ref-name-min-w;
  width: calc(
    100% - #{$detail-ref-code} - #{$detail-ref-label1} - #{$detail-ref-btn1} - #{$detail-ref-btn2} -
      #{$detail-group-text-label}
  );
}
.ref-name-input-3 {
  min-width: $detail-ref-name-min-w;
  width: calc(100% - #{$detail-ref-code} - #{$detail-group-text-label});
}
//--------------------------------------------------
.ref-code-input-field-1 {
  width: $detail-ref-code;
}
.ref-code-input-field-2 {
  width: calc(100% - #{$detail-ref-btn1});
}
.ref-code-input-field-3 {
  width: calc(100% - #{$detail-ref-btn1} - #{$detail-ref-label2});
}

.ref-name-input-field-1 {
  min-width: $detail-ref-name-min-w;
  width: calc(100% - #{$detail-ref-code} - #{$detail-ref-btn1});
}
.ref-name-input-field-2 {
  min-width: $detail-ref-name-min-w;
  width: calc(
    100% - #{$detail-ref-code} - #{$detail-ref-label1} - #{$detail-ref-btn1} - #{$detail-ref-btn2}
  );
}
.ref-name-input-field-3 {
  min-width: $detail-ref-name-min-w;
  width: calc(100% - #{$detail-ref-code});
}
//--------------------------------------------------
</style>
