/**
 * 状態管理.
 *
 * @file store/index.js
 * @author Baek.JH (SBK)
 * @copyright Hitach Industry & Control Solutions, Ltd. All rights reserved.
 */

import Vue from 'vue'
import Vuex from 'vuex'

import { init } from '@/system/init/initStore'
import { authentication } from '@/system/authentication/authenticationStore'
import { search } from './searchStore'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: { init, authentication, search },
})
