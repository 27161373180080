<template>
  <b-breadcrumb v-bind="$attrs">
    <b-breadcrumb-item v-if="startWithHome" to="/home">
      <BaseIcon name="home" />
    </b-breadcrumb-item>
    <b-breadcrumb-item
      v-for="(item, index) in items"
      :key="index"
      :active="isLast(index)"
      @click="
        () => {
          if (item.routeInfo) {
            let routeInfo = item.routeInfo
            routeInfo.params.beforeScreenRouteInfo = item.beforeScreenRouteInfo
            $router.push(routeInfo)
          }
        }
      "
    >
      {{ item.name }}</b-breadcrumb-item
    >
  </b-breadcrumb>
</template>

<script>
export default {
  name: 'BaseBreadcrumb',

  props: {
    items: { type: Array, required: true },
    startWithHome: { type: Boolean, default: true },
  },

  methods: {
    /**
     * パンくずリストの最後尾であるか判定します.
     * @param {Number} index
     * @return {Boolean}
     */
    isLast(index) {
      return index === this.items.length - 1
    },
  },
}
</script>

<style lang="scss" scoped>
.breadcrumb {
  border-left: 10px solid #f06292;
  font-weight: bold;
  padding: 0.5rem 0rem;
}

.breadcrumb-item {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: #ccc;
  content: '\00BB';
}

.breadcrumb--active {
  color: #777;
}
</style>
