/**
 * 状態管理（認証処理用）.
 *
 * @file system/authentication/authenticationStore.js
 * @author Baek.JH (SBK)
 * @copyright Hitach Industry & Control Solutions, Ltd. All rights reserved.
 */

import { isNil } from 'lodash'
import AuthenticationService from './AuthenticationService'
import AzureAdAuthenticationService from '@/system/authentication/AzureAdAuthenticationService'
import router from '@/router'
import { SCREEN_ID } from '@/common/const.js'

const initialUser = JSON.parse(sessionStorage.getItem('user'))
const initialState = initialUser
  ? { status: { loggedIn: true }, user: initialUser }
  : { status: { loggedIn: false }, user: null }

export const authentication = {
  namespaced: true,
  state: initialState,
  actions: {
    /**
     * ログイン.
     *
     * @param {Object} loginRequest ログイン要求情報
     */
    async login({ commit }, loginRequest) {
      return await AuthenticationService.login(loginRequest).then(
        (responsedUser) => {
          if (responsedUser.accessToken) {
            sessionStorage.setItem('user', JSON.stringify(responsedUser))
          }
          commit('loginSuccess', responsedUser)
          return Promise.resolve(responsedUser)
        },
        (error) => {
          commit('loginFailure')
          return Promise.reject(error)
        }
      )
    },
    /**
     * ログアウト.
     *
     * ログアウト処理後、ログイン画面に戻します。
     */
    async logout({ commit, dispatch }) {
      await AzureAdAuthenticationService.logout()

      sessionStorage.removeItem('user')
      commit('logout')
      dispatch('init/removeInitInfo', {}, { root: true })
      router.push({ name: SCREEN_ID.LOGIN })
    },
    /**
     * ブロック選択.
     *
     * @param {Object} selectBlockRequest ブロック選択要求情報
     */
    selectBlock({ commit }, selectBlockRequest) {
      return AuthenticationService.selectBlock(selectBlockRequest).then(
        (responsedUser) => {
          console.log('accessToken', responsedUser)
          if (responsedUser.accessToken) {
            sessionStorage.setItem('user', JSON.stringify(responsedUser))
          }
          commit('loginSuccess', responsedUser)
          return Promise.resolve(responsedUser)
        },
        (error) => {
          commit('loginFailure')
          return Promise.reject(error)
        }
      )
    },
    /**
     * 言語選択.
     *
     * @param {Object} selectLangRequest 言語選択要求情報
     */
    selectLang({ commit }, selectLangRequest) {
      return AuthenticationService.selectLang(selectLangRequest).then(
        (responsedUser) => {
          console.log('accessToken', responsedUser)
          if (responsedUser.accessToken) {
            sessionStorage.setItem('user', JSON.stringify(responsedUser))
          }
          commit('loginSuccess', responsedUser)
          return Promise.resolve(responsedUser)
        },
        (error) => {
          commit('loginFailure')
          return Promise.reject(error)
        }
      )
    },
  },
  getters: {
    getUserId: (state) => {
      return isNil(state.user) ? '' : state.user.userId
    },
    getUserName: (state) => {
      return isNil(state.user) ? '' : state.user.userName
    },
    getRoleId: (state) => {
      return isNil(state.user) ? '' : state.user.roleId
    },
    getUserDepartmentId: (state) => {
      return isNil(state.user) ? '' : state.user.belongDepartmentId
    },
    getUserDepartmentCode: (state) => {
      return isNil(state.user) ? '' : state.user.belongDepartmentCode
    },
    getUserDepartmentName: (state) => {
      return isNil(state.user) ? '' : state.user.belongDepartmentName
    },
    getBlockCode: (state) => {
      return isNil(state.user) ? '' : state.user.blockCode
    },
    getBlockName: (state) => {
      return isNil(state.user) ? '' : state.user.blockName
    },
    getBlocks: (state) => {
      if (state.user) {
        return state.user.blockRoles
          .map((e) => ({
            ['value']: e.blockCode,
            ['text']: e.blockName,
          }))
          .sort(function (a, b) {
            let valueA = a.value.toUpperCase()
            let valueB = b.value.toUpperCase()
            if (valueA < valueB) {
              return -1
            }
            if (valueA > valueB) {
              return 1
            }
            return 0
          })
      }
      return []
    },
    getLanguage: (state) => {
      return isNil(state.user) ? '' : state.user.language
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true
      state.user = user
    },
    loginFailure(state) {
      state.status.loggedIn = false
      state.user = null
    },
    logout(state) {
      state.status.loggedIn = false
      state.user = null
    },
  },
}
