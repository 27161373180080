import store from '@/store'
import { MSG_TYPE, SCREEN_ID, PLACE_KIND, PLACE_LEVEL } from '@/common/const.js'
import { isNil, isEmpty } from 'lodash'
import _ from 'lodash'

/**
 * CSVとしてダウンロード処理を行います.
 * @param {String} filename
 * @param {Array} headers
 * @param {Array} keys
 * @param {Object} data
 */
// export function downloadCSV(filename, fields, data) {
//   const headers = fields.map((field) => field.label)
//   const keys = fields.map((field) => field.key)

//   const csvHeaders = headers.map((header) => `"${header}"`).join(',') + '\n'
//   const csvData = data
//     .map((row) =>
//       keys.map((key) => (isNil(row[key]) ? '' : `"${row[key]}"`)).join(',')
//     )
//     .join('\n')
//   const bom = new Uint8Array([0xef, 0xbb, 0xbf])
//   const blob = new Blob([bom, csvHeaders, csvData], { type: 'text/csv' })

//   if (navigator.msSaveOrOpenBlob) {
//     navigator.msSaveOrOpenBlob(blob, filename)
//   } else {
//     const url = window.URL.createObjectURL(blob)
//     const link = document.createElement('a')
//     link.href = url
//     link.setAttribute('download', filename + '.csv')
//     document.body.appendChild(link)
//     link.click()
//     link.remove()
//     window.URL.revokeObjectURL(url)
//   }
// }

/**
 * CSVとしてダウンロード処理を行います.
 * @param {String} filename
 * @param {Object} data
 */
export function downloadCSV(filename, data) {
  const encoding = require('encoding-japanese')
  const str_array = encoding.stringToCode(data)
  const sjis_array = encoding.convert(str_array, 'SJIS', 'UNICODE')
  const uint8_array = new Uint8Array(sjis_array)

  const blob = new Blob([uint8_array], { type: 'text/csv' })
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  link.remove()
  window.URL.revokeObjectURL(url)
}

/**
 * マスターデータからコンボボックスのオプションを取得
 * @param {*} masterName
 */
export function getListOptions(masterName, addBlank = true) {
  let listOptions = store.getters['init/getMasterCode'](masterName)

  // 先頭に空白を追加
  if (listOptions && addBlank) {
    listOptions.unshift({
      ['value']: null,
      ['text']: '',
    })
  }

  return listOptions
}

//s100_system_configsの情報
//system_class_code
const SYSTEM_CLASS_CODE = {
  COMN: 'COMN',
  H002: 'H002',
  FEE_CALC_ROUND: 'FEE_CALC_ROUND', //運賃計算時の税割り戻し丸め方法
}
//s100_system_configsの情報
//system_management_code
const SYSTEM_MANAGEMENT_CODE = {
  COMN_MAXCT: 'MAXCT',
  COMN_PGCT: 'PGCT',
  COMN_PGCTR: 'PGCTR',
  H002_DefaultLatitude: 'DefaultLatitude', //緯度
  H002_DefaultLongitude: 'DefaultLongitude', //経度
  ROUND_RULE: 'ROUND_RULE', //運賃計算時の税割り戻し丸め方法
}

//最大取得件数
export function getSearchMaxCount() {
  return store.getters['init/getSystemManagement'](SYSTEM_CLASS_CODE.COMN)(
    SYSTEM_MANAGEMENT_CODE.COMN_MAXCT
  )
}

//1ページの表示件数
export function getCountPerPage() {
  return store.getters['init/getSystemManagement'](SYSTEM_CLASS_CODE.COMN)(
    SYSTEM_MANAGEMENT_CODE.COMN_PGCT
  )
}

//1ページの表示件数(参照)
export function getCountPerReferencePage() {
  return store.getters['init/getSystemManagement'](SYSTEM_CLASS_CODE.COMN)(
    SYSTEM_MANAGEMENT_CODE.COMN_PGCTR
  )
}

//税抜き金額計算
export function getCostWithoutTax(cost, taxPercentage) {
  let ROUND = store.getters['init/getSystemManagement'](
    SYSTEM_CLASS_CODE.FEE_CALC_ROUND
  )(SYSTEM_MANAGEMENT_CODE.ROUND_RULE)

  console.log('ROUND', ROUND)

  //let costWithoutTax = cost / (1 + taxPercentage / 100.0)
  let costWithoutTax = (cost * 100) / (100 + taxPercentage) // #2731
  switch (ROUND) {
    case 'ROUND_UP': //切り上げ
      costWithoutTax = Math.ceil(costWithoutTax)
      break
    case 'ROUND_DOWN': //切り下げ
      costWithoutTax = Math.floor(costWithoutTax)
      break
    case 'ROUND': //四捨五入
      costWithoutTax = Math.round(costWithoutTax)
      break
    default:
      costWithoutTax = cost
  }

  console.log('costWithoutTax', costWithoutTax)
  return costWithoutTax
}

//地図の緯度経度情報
export function getDefaultCoordinates() {
  //return [35.70241, 139.7754] 秋葉原ICS本社位置
  //return [35.67090, 139.74214] コマツ本社位置
  let latitude = store.getters['init/getSystemManagement'](
    SYSTEM_CLASS_CODE.H002
  )(SYSTEM_MANAGEMENT_CODE.H002_DefaultLatitude)
  let longitude = store.getters['init/getSystemManagement'](
    SYSTEM_CLASS_CODE.H002
  )(SYSTEM_MANAGEMENT_CODE.H002_DefaultLongitude)

  if (checkCoordinatesRange(latitude, longitude)) {
    return [latitude, longitude]
  } else {
    return [35.6709, 139.74214]
  }
}

export function openSubModal(obj, modalId) {
  console.log('openSubModal:', modalId)
  obj.$bvModal.show(modalId)
}

export function openMsgModal(obj, type, msg) {
  var emitName
  var modalId
  switch (type) {
    case MSG_TYPE.E:
      emitName = 'set-error-msg'
      modalId = 'error-modal'
      break
    case MSG_TYPE.I:
      emitName = 'set-info-msg'
      modalId = 'info-modal'
      break
    case MSG_TYPE.W:
      emitName = 'set-warn-msg'
      modalId = 'warn-modal'
      break

    default:
      break
  }

  obj.$emit(emitName, msg)
  obj.$bvModal.show(modalId)
}

/**
 * マスタ名取得
 * @param {String} masterName
 * @param {String} code
 */
export function getMasterName(masterName, code) {
  let list = getListOptions(masterName)
  let name
  list.some((element) => {
    if (element.value == code) {
      name = element.text
      return true
    }
  })
  return name
}

export function getDateFromDateString8(dateString) {
  let year = dateString.substring(0, 4)
  let monthIndex = Number(dateString.substring(4, 6)) - 1
  let day = dateString.substring(6, 8)

  return new Date(year, monthIndex, day, 0, 0, 0)
}

export function formatYYYYMM(obj, val) {
  if (isNil(val) || isEmpty(val)) {
    return null
  } else {
    return obj.$moment(val).format('YYYYMM')
  }
}

export function formatYYYYMMDD(obj, val) {
  if (isNil(val) || isEmpty(val)) {
    return null
  } else {
    return obj.$moment(val).format('YYYYMMDD')
  }
}

export function displaYyyymmdd(obj, val) {
  if (isNil(val) || isEmpty(val)) {
    return null
  } else {
    if (val.length == 8) {
      return obj.$moment(val).format('YYYY/MM/DD')
    }
    return val ? val.replace(/\//g, '/') : val
  }
}

export function formatHHmmss(val) {
  if (isNil(val) || isEmpty(val)) {
    return null
  } else {
    //return val.replaceAll(':', '')
    var data = val.replaceAll(':', '')
    if (data.length == 4) {
      data = data + '00'
    }
    return data
  }
}

export function displayHHmmss(val) {
  if (isNil(val) || isEmpty(val)) {
    return null
  } else {
    if (val.match(/\d{2}/g) != null) {
      return val.match(/\d{2}/g).join(':')
    }
  }
}

export function formatYYYYYMMDDHHmm(val) {
  if (isNil(val) || isEmpty(val)) {
    return null
  } else {
    return (
      val.replace(/\s/gi, '').replaceAll('/', '').replaceAll(':', '') + '00'
    )
  }
}

export function formatYYYYYMMDDHHmmss(val) {
  if (isNil(val) || isEmpty(val)) {
    return null
  } else {
    return val.replace(/\s/gi, '').replaceAll('/', '').replaceAll(':', '')
  }
}

export function displayYYYYYMMDDHHmm(obj, val) {
  if (isNil(val) || isEmpty(val)) {
    return null
  } else {
    let date = val.substr(0, 8)
    let time = val.substr(8, 6)
    return obj.$moment(date + ' ' + time).format('YYYY/MM/DD HH:mm')
  }
}

export function displayYYYYYMMDDHHmmss(obj, val) {
  if (isNil(val) || isEmpty(val)) {
    return null
  } else {
    let date = val.substr(0, 8)
    let time = val.substr(8, 8)
    return obj.$moment(date + ' ' + time).format('YYYY/MM/DD HH:mm:ss')
  }
}

export function checkCoordinatesRange(latitude, longitude) {
  const MaxLat = 45.5722222
  const MinLat = 20.4252777
  const MaxLon = 153.9863888
  const MinLon = 122.9336111

  if (!latitude || !longitude) {
    return false
  }

  return (
    MaxLat > latitude &&
    latitude > MinLat &&
    MaxLon > longitude &&
    longitude > MinLon
  )
}

/**
 * Snake-Case変換 (詳細画面のList対応)
 * ex) return -> receive_order_id
 * @param {} items
 */
export function convertSnakeCace(items) {
  var datas = []
  for (let item in items) {
    let row = []
    for (let key in items[item]) {
      //reserve01->[reserve_01]を[reserve01]で再設定するため。
      let xxSnake = _.snakeCase(key)
      let snakedomain = xxSnake
        .replace('_0', '0')
        .replace('_1', '1')
        .replace('_2', '2')
        .replace('_3', '3')
        .replace('_4', '4')
        .replace('_5', '5')
        .replace('_6', '6')
        .replace('_7', '7')
        .replace('_8', '8')
        .replace('_9', '9')

      row.push({
        [snakedomain]: items[item][key],
      })
    }
    datas.push(row.reduce((l, r) => Object.assign(l, r), {}))
  }
  return datas
}

/**
 * Camel-Case変換 (全般対応：登録)
 * ex) return -> receiveOrderId
 * @param {*} items
 */
export function convertCamelCace(items) {
  var datas = []
  for (let item in items) {
    let row = []
    for (let key in items[item]) {
      var camelCased = _.camelCase(key)
      row.push({
        [camelCased]: items[item][key],
      })
    }
    datas.push(row.reduce((l, r) => Object.assign(l, r), {}))
  }
  return datas
}

/**
 * csvﾌｧｲﾙ出力名称作成
 * @param {*} obj
 * @param {*} screenId
 */
export function csvFileName(obj, screenId) {
  let extention = '.csv'
  let nowDateTime = obj.$moment(new Date()).format('YYYYMMDDHHmmss')
  let prefix = null

  switch (screenId) {
    case SCREEN_ID.RECEIVE_ORDER_LIST:
      prefix = 'EA01F001'
      break
    case SCREEN_ID.PLANNING_ORDER_LIST:
      prefix = 'D001-10'
      break
    case SCREEN_ID.FARE_LIST:
      prefix = 'G003-10'
      break
    case SCREEN_ID.AREA_LIST:
      prefix = 'ED01F001'
      break
    case SCREEN_ID.DEPARTMENT_LIST:
      prefix = 'MA01F001'
      break
    case SCREEN_ID.OPERATION_TIME_PATTERN_LIST:
      prefix = 'MH01F001'
      break
    case SCREEN_ID.PLACE_LIST:
      prefix = 'MB01F001'
      break
    case SCREEN_ID.PRODUCT_LIST:
      prefix = 'MD01F001'
      break
    case SCREEN_ID.TARIFF_EDIT_MODAL:
      prefix = 'H013-40' // タリフ編集Modal
      break
    case SCREEN_ID.FREIGHT_ITEM_LIST:
      prefix = 'H014-10'
      break
    case SCREEN_ID.REAL_VEHICLE_LIST:
      prefix = 'H016-10'
      break
    case SCREEN_ID.VEHICLE_LIST:
      prefix = 'MC01F001'
      break
    case SCREEN_ID.LOGMANAGEMENT_LIST:
      prefix = 'SC01F001'
      break
    case SCREEN_ID.USER_LIST:
      prefix = 'SA01F001'
      break
    case SCREEN_ID.PAYMENT_LIST:
      prefix = 'G002-10'
      break
    case SCREEN_ID.BILLING_LIST:
      prefix = 'G001-10'
      break
    default:
      prefix = ''
  }

  let newCsvName = prefix + '_' + nowDateTime.toString() + extention
  console.log('newCsvName=' + newCsvName)
  return newCsvName
}

/**
 * （場所マスタ）場所区分により（搬出・搬入）場所区分を設定する
 */
export function changePlaceKind(placeKind) {
  let placeType = null
  switch (placeKind) {
    case PLACE_KIND.PREFECTURE:
      placeType = PLACE_LEVEL.PREFECTURE
      break
    case PLACE_KIND.CITY:
      placeType = PLACE_LEVEL.CITY
      break
    default:
      placeType = PLACE_LEVEL.PLACE
      break
  }
  return placeType
}

// 画面遷移時の確認
window.onbeforeunload = function (e) {
  if (window.location.href.endsWith('/login')) {
    // ログイン画面からの画面遷移はOKとする。
    return
  }

  // ブラウザの更新ボタン押下防止のためメッセージを出す。
  // ※メッセージのカスタマイズは不可
  e.preventDefault()
  e.returnValue = ''
}
