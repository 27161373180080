<template>
  <b-pagination
    :v-model="currentPage"
    :size="size"
    :align="align"
    :total-rows="totalRows"
    :per-page="perPage"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'BasePagination',

  inheritAttrs: false,

  props: {
    /**
     * 表示中のページ番号.
     * @type {Number}
     */
    currentPage: {
      type: Number,
      default: 1,
    },

    size: {
      type: String,
      default: '',
    },

    align: {
      type: String,
      default: '',
    },

    /**
     * 1ページ毎に表示する行.
     * @type {String}
     */
    perPage: {
      type: [Number, String],
      default: 15,
    },

    totalRows: {
      type: Number,
      default: 0,
    },
  },
}
</script>

<style scoped></style>
