<template>
  <b-input-group size="sm" v-if="columnInfo1.visible()">
    <template v-slot:prepend v-if="labelVisible">
      <b-input-group-text class="group-text"
        >{{ columnInfo1.label }}
        <span v-if="rule1 == INPUT_TYPE_CHECK.REQUIRED">*</span>
      </b-input-group-text>
    </template>
    <BaseTextInput
      v-model="_codeValue1"
      :columnInfo="columnInfo1"
      :error-visible="errorVisible"
      :rules="rule1"
      :state="state"
      class="txtWidth"
    />
    <BaseTextInput
      v-if="columnInfo2 != null"
      v-model="_codeValue2"
      :columnInfo="columnInfo2"
      :error-visible="errorVisible"
      :rules="rule2"
      :state="state"
      class="txtWidth"
    />
    <BaseTextInput
      v-if="columnInfo3 != null"
      v-model="_codeValue3"
      :columnInfo="columnInfo3"
      :error-visible="errorVisible"
      :rules="rule3"
      :state="state"
      class="txtWidth"
    />
    <BaseTextInput
      v-if="columnInfo4 != null"
      v-model="_codeValue4"
      :columnInfo="columnInfo4"
      :error-visible="errorVisible"
      :rules="rule4"
      :state="state"
      class="txtWidth"
    />
    <b-input-group-append>
      <BaseButton
        v-if="btnColumnInfo != null"
        :columnInfo="btnColumnInfo"
        @click="$emit('click')"
      />
    </b-input-group-append>
  </b-input-group>
</template>

<script>
export default {
  name: 'BaseTextInputGroup',
  inheritAttrs: false,
  props: {
    labelVisible: {
      type: Boolean,
      default: false,
    },

    codeValue1: {
      type: [String, Number],
      default: '',
    },
    codeValue2: {
      type: [String, Number],
      default: '',
    },
    codeValue3: {
      type: [String, Number],
      default: '',
    },
    codeValue4: {
      type: [String, Number],
      default: '',
    },

    /**
     * 定義情報.
     * @type {Object}
     * @requires
     */
    columnInfo1: {
      type: Object,
      required: true,
    },

    columnInfo2: {
      type: Object,
      default: null,
    },

    columnInfo3: {
      type: Object,
      default: null,
    },

    columnInfo4: {
      type: Object,
      default: null,
    },

    btnColumnInfo: {
      type: Object,
      default: null,
    },

    /**
     * バリデーションチェックの状態を表示するか.
     * @type {Boolean}
     */
    state: {
      type: Boolean,
      default: false,
    },

    /**
     * エラーメッセージを表示するか.
     * @type {Boolean}
     */
    errorVisible: {
      type: Boolean,
      default: true,
    },

    /**
     * 入力検証ルール.
     * @type {String}
     */
    rule1: {
      type: Array,
      default: () => [],
    },
    /**
     * 入力検証ルール.
     * @type {String}
     */
    rule2: {
      type: Array,
      default: () => [],
    },
    /**
     * 入力検証ルール.
     * @type {String}
     */
    rule3: {
      type: Array,
      default: () => [],
    },
    /**
     * 入力検証ルール.
     * @type {String}
     */
    rule4: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    _codeValue1: {
      get() {
        return this.codeValue1
      },
      set(v) {
        if (this.codeValue1 !== v) this.$emit('update:codeValue1', v)
      },
    },
    _codeValue2: {
      get() {
        return this.codeValue2
      },
      set(v) {
        if (this.codeValue2 !== v) this.$emit('update:codeValue2', v)
      },
    },
    _codeValue3: {
      get() {
        return this.codeValue3
      },
      set(v) {
        if (this.codeValue3 !== v) this.$emit('update:codeValue3', v)
      },
    },
    _codeValue4: {
      get() {
        return this.codeValue4
      },
      set(v) {
        if (this.codeValue4 !== v) this.$emit('update:codeValue4', v)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.txtWidth {
  width: 80px;
}
</style>
