/**
 * メイン.
 *
 * @file main.js
 * @author Kim.HY (SBK)
 * @copyright Hitach Industry & Control Solutions, Ltd. All rights reserved.
 */

import Vue from 'vue'
import router from '@/router'
import store from '@/store'
import App from '@/system/main/App.vue'
import vueMoment from 'vue-moment'
import VueChartJs from 'vue-chartjs'

import '@/assets/style/app.css'
import '@/assets/style/app.scss'
import '../public/fonts/fontawesome-free-5.11.2-web/css/all.css'
import '@/assets/style/custom.scss'

import '@/plugins/bootstrap-vue'
import '@/plugins/validate.js'
import '@/common/components/components'
import '@/common/const'

import AzureAdAuthenticationService from '@/system/authentication/AzureAdAuthenticationService'

Vue.use(vueMoment)
Vue.use(VueChartJs)

appStartup()

async function appStartup() {
  let AUTH_TENANT_ID = process.env.VUE_APP_ADD_TENANT_ID || ''
  let AUTH_CLIENT_ID = process.env.VUE_APP_AAD_CLIENT_ID || ''

  AzureAdAuthenticationService.configure(AUTH_TENANT_ID, AUTH_CLIENT_ID)

  new Vue({
    render: (h) => h(App),
    router,
    store,
  }).$mount('#app')
}
