<template>
  <!-- no-border-collapse -->
  <b-table
    ref="scrollable-table"
    data-class="scrollable-table"
    class="scrollable-table"
    :style="styleObject"
    bordered
    hover
    responsive
    selectable
    small
    sticky-header
    outlined
    :fields="fields"
    :items="items"
    :tbody-tr-class="rowClass"
    :per-page="perPage"
    :current-page="currentPage"
    :select-mode="selectMode"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:cell(key1)="data">
      {{ perPage * (currentPage - 1) + data.index + 1 }}
    </template>

    <template
      v-if="screenId == SCREEN_ID.VEHICLE_OPERATION_LIST"
      v-slot:cell(schedule_begin_time)="row"
    >
      <b-form-timepicker
        v-model="row.item.schedule_begin_time"
        size="sm"
        now-button
        reset-button
        class="mb-2"
        @input="changeTime(row, 1)"
      ></b-form-timepicker>
    </template>

    <template
      v-if="screenId == SCREEN_ID.VEHICLE_OPERATION_LIST"
      v-slot:cell(schedule_end_time)="row"
    >
      <b-form-timepicker
        v-model="row.item.schedule_end_time"
        size="sm"
        now-button
        reset-button
        class="mb-2"
        @input="changeTime(row, 2)"
      ></b-form-timepicker>
    </template>
    <template
      v-if="screenId == SCREEN_ID.VEHICLE_OPERATION_LIST"
      v-slot:cell(vehicle_operation_kind)="row"
    >
      <b-form-select
        v-model="row.item.vehicle_operation_kind"
        size="sm"
        @input="changeTime(row, 2)"
        :options="getListOptions(MASTER_CODE.VEHICLE_OPERATION_KIND, false)"
        v-bind="$attrs"
        v-on="$listeners"
      />
    </template>
  </b-table>
</template>

<script>
import { getListOptions } from '@/common/Common'

export default {
  name: 'BaseScrollableTable',

  inheritAttrs: false,

  props: {
    /**
     * 列定義リスト.
     * @type {Array}
     */
    fields: {
      type: Array,
      required: true,
    },

    /**
     * 行リスト.
     * @type {Array}
     */
    items: {
      type: Array,
      default: () => [],
    },

    /**
     * 選択行リスト.
     * @type {Array}
     */
    selectedItems: {
      type: Array,
      default: () => [],
    },

    /**
     * 選択モード.
     * @type {String}
     */
    selectMode: {
      type: String,
      default: 'range',
    },

    /**
     * デフォルトの高さ.
     * @type {String}
     */
    height: {
      type: String,
      default: '0px',
    },

    /**
     * 1ページ毎に表示する行.
     * @type {String}
     */
    perPage: {
      type: [Number, String],
      default: 15,
    },

    /**
     * 表示中のページ番号.
     * @type {String}
     */
    currentPage: {
      type: Number,
    },

    screenId: {
      type: String,
    },
  },

  computed: {
    /**
     * テーブルのスタイル.
     * @return {Object}
     */
    styleObject() {
      return {
        'min-height': this.height,
      }
    },
  },

  methods: {
    getListOptions,

    /**
     * 選択行に適用するCSSクラス名を返します.
     * @param {Object} item 選択行.
     */
    rowClass(item) {
      if (!item) return
      return item.color
    },

    /**
     * 時刻変更イベント時、処理
     */
    changeTime(row, inputKind) {
      if (inputKind == 1) {
        row.item.modify_flag = 'changed'
      }
      if (inputKind == 2) {
        row.item.modify_flag = 'changed'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.scrollable-table {
  font-size: 0.9rem;
}
</style>
