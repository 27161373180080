/**
 * 画面表示用部品.
 *
 * @file plugins/bootstrap-vue.js
 * @author Kim.HY (SBK)
 * @copyright Hitach Industry & Control Solutions, Ltd. All rights reserved.
 */

import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
