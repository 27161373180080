<template>
  <b-button
    v-if="columnInfo.visible()"
    data-class="base-button"
    size="sm"
    :disabled="columnInfo.disable() || disabled"
    :id="columnInfo.domainColumnName"
    :variant="currentVariant"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <span class="base-button">
      <em v-if="icon" :class="iconClassList" />
      <slot>
        {{ currentLabel }}
      </slot>
    </span>
  </b-button>
</template>

<script>
export default {
  name: 'BaseButton',

  inheritAttrs: false,

  props: {
    /**
     * 定義情報.
     * @type {Object}
     * @requires
     */
    columnInfo: {
      type: Object,
      required: true,
    },

    /**
     * アイコン名.
     * @type {String}
     */
    icon: {
      type: String,
      default: '',
    },

    /**
     * 押下不可かどうか.
     * @type {Boolean}
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    isToggled: {
      type: Boolean,
      default: false,
    },

    toggleLabel: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: 'success',
    },
    toggleVariant: {
      type: String,
      default: 'danger',
    },
  },

  computed: {
    /**
     * アイコンを表示するためのCSSクラスリスト.
     * @return {Array}
     */
    iconClassList() {
      return ['fa', `fa-${this.icon}`]
    },

    currentLabel() {
      if (!this.toggleLabel) {
        return this.columnInfo.label
      }
      if (this.isToggled) {
        return this.toggleLabel
      } else {
        return this.columnInfo.label
      }
    },

    currentVariant() {
      if (this.isToggled) {
        return this.toggleVariant
      } else {
        return this.variant
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$backColor: #0080ff;
$mainStyle: #000000;

.base-button {
  font-size: 0.8rem;
  // color: $backColor;
}
</style>
