import Vue from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

function entryComponents(require) {
  require.keys().forEach((fileName) => {
    const componentConfig = require(fileName)

    const componentName = upperFirst(
      camelCase(
        fileName
          .split('/')
          .pop()
          .replace(/\.\w+$/, '')
      )
    )

    Vue.component(componentName, componentConfig.default || componentConfig)
  })
}

entryComponents(
  require.context('@/common/components/', true, /[A-Z]\w+\.(vue|js)$/)
)
