<template>
  <b-table
    data-class="base-table"
    class="table table-bordered base-table"
    bordered
    hover
    responsive
    selectable
    small
    sticky-header
    outlined
    :selectMode="selectMode"
    :fields="fields"
    :items="items"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <!-- selected-variant="danger" -->
    <template v-slot:cell(key1)="data">
      {{ data.index + 1 }}
    </template>

    <template v-slot:cell(chkRepresentative)="row">
      <input type="checkbox" v-model="row.item.chkRepresentative" />
    </template>
  </b-table>
</template>

<script>
export default {
  name: 'BaseTable',

  inheritAttrs: false,

  props: {
    /**
     * 列定義リスト.
     * @type {Array}
     */
    fields: {
      type: Array,
      required: true,
    },

    /**
     * 行リスト.
     * @type {Array}
     */
    items: {
      type: Array,
      default: () => [],
    },

    /**
     * 選択行リスト.
     * @type {Array}
     */
    selectedItems: {
      type: Array,
      default: () => [],
    },

    /**
     * 選択モード.
     * @type {String}
     */
    selectMode: {
      type: String,
      default: 'range',
    },

    /**
     * デフォルトの高さ.
     * @type {String}
     */
    height: {
      type: String,
      default: '0px',
    },
  },
}
</script>

<style lang="scss" scoped>
.base-table {
  font-size: 0.8rem;
  th,
  td {
    padding: 5px;
    vertical-align: middle;
  }

  th {
    background-color: $primary;
    color: #eee;
    padding: 0 1rem;
    text-align: center;
    width: 130px;
  }
}
</style>
