<template>
  <validation-provider
    :rules="_rules"
    :name="columnInfo.domainColumnName"
    v-slot="{ validated, touched, passed, errors }"
  >
    <!-- :name="columnInfo.label" -->
    <b-form-input
      v-if="columnInfo.visible()"
      v-model="_value"
      data-class="base-text-input"
      size="sm"
      :type="type"
      :disabled="columnInfo.disable() || disabled"
      :id="columnInfo.domainColumnName"
      :name="columnInfo.domainColumnName"
      :maxlength="maxLength"
      :minlength="minLength"
      :state="(state && validated) || (state && touched) ? passed : null"
      v-bind="$attrs"
      v-on="$listeners"
    />
    <span v-if="errorVisible" class="text-danger errorText">
      {{ errors[0] }}
    </span>
  </validation-provider>
</template>

<script>
export default {
  name: 'BaseTextInput',

  inheritAttrs: false,

  props: {
    value: {
      type: [String, Number],
      default: '',
    },

    /**
     * 定義情報.
     * @type {Object}
     * @requires
     */
    columnInfo: {
      type: Object,
      required: true,
    },

    /**
     * 入力不可か.
     * @type {Boolean}
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * バリデーションチェックの状態を表示するか.
     * @type {Boolean}
     */
    state: {
      type: Boolean,
      default: false,
    },

    validated: {
      type: Boolean,
      default: true,
    },

    touched: {
      type: Boolean,
      default: true,
    },

    passed: {
      type: Boolean,
      default: false,
    },

    /**
     * 入力検証ルール.
     * @type {String}
     */
    rules: {
      type: Array,
      default: () => [],
    },

    /**
     * エラーメッセージを表示するか.
     * @type {Boolean}
     */
    errorVisible: {
      type: Boolean,
      default: true,
    },

    // password, number
    type: {
      type: String,
      default: 'text',
    },

    maxLength: {
      type: [String, Number],
    },

    minLength: {
      type: [String, Number],
    },

    minValue: {
      type: [String, Number],
    },

    maxValue: {
      type: [String, Number],
    },
  },

  computed: {
    _value: {
      get() {
        return this.value
      },

      set(v) {
        if (this.value !== v) this.$emit('input', v)
      },
    },

    /**
     * 入力検証ルール.
     * @return {String}
     */
    _rules() {
      let rules = ''
      this.rules.forEach((element) => {
        if (element == this.INPUT_TYPE_CHECK.REQUIRED) {
          rules += 'required|'
        }
        if (element == this.INPUT_TYPE_CHECK.MAXLENGTH) {
          rules += `max:${this.maxLength}|`
        }
        if (element == this.INPUT_TYPE_CHECK.MINLENGTH) {
          rules += `min:${this.minLength}|`
        }
        if (element == this.INPUT_TYPE_CHECK.ONLY_NUMERIC) {
          rules += 'integer|'
        }
        if (element == this.INPUT_TYPE_CHECK.NUMERIC) {
          rules += 'numeric|'
        }
        if (element == this.INPUT_TYPE_CHECK.NUMBER_POSITIVE_NEGATIVE) {
          rules += 'numberPositiveNegative|'
        }
        if (element == this.INPUT_TYPE_CHECK.ONLY_ALPHANUMERIC) {
          rules += 'onlyAlphaNumeric|'
        }
        if (element == this.INPUT_TYPE_CHECK.ALPHANUMERIC) {
          rules += 'alphaNumeric|'
        }
        if (element == this.INPUT_TYPE_CHECK.FULLCHAR) {
          rules += 'fullchar|'
        }
        if (element == this.INPUT_TYPE_CHECK.FLOAT) {
          rules += 'float|'
        }
        if (element == this.INPUT_TYPE_CHECK.DATE) {
          rules += 'date|'
        }
        if (element == this.INPUT_TYPE_CHECK.TIME) {
          rules += 'time|'
        }
        if (element == this.INPUT_TYPE_CHECK.ZIPNUMERIC) {
          rules += 'zipNumeric|'
        }
        if (element == this.INPUT_TYPE_CHECK.MAXVALUE) {
          rules += `max_value:${this.maxValue}|`
        }
        if (element == this.INPUT_TYPE_CHECK.MINVALUE) {
          rules += `min_value:${this.minValue}|`
        }
        if (element == this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE) {
          rules += 'alphaNumericCode|'
        }
        if (element == this.INPUT_TYPE_CHECK.EMAIL) {
          rules += 'email|'
        }
        if (element == this.INPUT_TYPE_CHECK.ONETONINE) {
          rules += 'oneToNine|'
        }
      })

      return rules
    },
  },
}
</script>
