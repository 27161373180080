<template>
  <b-input-group size="sm" v-if="columnInfo.visible()">
    <template v-slot:prepend>
      <b-input-group-text class="group-text"
        >{{ columnInfo.label }}
        <span v-if="rules[0] == INPUT_TYPE_CHECK.REQUIRED">*</span>
      </b-input-group-text>
    </template>
    <BaseTextInput
      class="detail-input"
      v-if="
        columnType === COLUMN_TYPE.TEXT || columnType === COLUMN_TYPE.PASSWORD
      "
      :type="columnType === COLUMN_TYPE.PASSWORD ? 'password' : 'text'"
      v-model="_value"
      :columnInfo="columnInfo"
      :error-visible="errorVisible"
      :rules="rules"
      :state="state"
      :maxLength="maxLength"
      :minLength="minLength"
      :minValue="minValue"
      :maxValue="maxValue"
    />
    <BaseSelect
      class="detail-input"
      v-if="columnType === COLUMN_TYPE.LIST"
      v-model="_value"
      :columnInfo="columnInfo"
      :options="options"
      :error-visible="errorVisible"
      :rules="rules"
      :state="state"
      @change="$emit('change')"
    />
    <BaseRadioGroup
      class="detail-input"
      v-if="columnType === COLUMN_TYPE.RADIOGROUP"
      v-model="_value"
      :columnInfo="columnInfo"
      :options="options"
      :error-visible="errorVisible"
      heightStyle=""
      :rules="rules"
      :state="state"
      @change="$emit('change')"
    />
    <!-- class="detail-input base-checkbox" -->
    <BaseCheckbox
      class="detail-input"
      v-if="columnType === COLUMN_TYPE.CHECKBOX"
      v-model="_value"
      :columnInfo="columnInfo"
      :error-visible="errorVisible"
      :rules="rules"
      :state="state"
      :displayLabel="false"
    />
    <BaseCheckboxGroup
      class="detail-input"
      v-if="columnType === COLUMN_TYPE.CHECKBOXGROUP"
      v-model="_value"
      :columnInfo="columnInfo"
      :options="options"
      :error-visible="errorVisible"
      heightStyle=""
      :rules="rules"
      :state="state"
    />
    <BaseDateInput
      class="detail-input"
      v-if="columnType === COLUMN_TYPE.CALENDAR"
      v-model="_value"
      :columnInfo="columnInfo"
      :error-visible="errorVisible"
      :rules="rules"
      :state="state"
    />
    <BaseTimeInput
      class="detail-input"
      v-if="columnType === COLUMN_TYPE.TIME"
      v-model="_value"
      :columnInfo="columnInfo"
      :error-visible="errorVisible"
      :rules="rules"
      :state="state"
    />
  </b-input-group>
</template>

<script>
export default {
  name: 'BaseDetailInputGroup',

  inheritAttrs: false,

  props: {
    value: {
      type: [String, Number, Boolean, Array],
      default: null,
    },

    /**
     * 定義情報.
     * @type {Object}
     * @requires
     */
    columnInfo: {
      type: Object,
      required: true,
    },

    /**
     * バリデーションチェックの状態を表示するか.
     * @type {Boolean}
     */
    state: {
      type: Boolean,
      default: false,
    },

    /**
     * 入力検証ルール.
     * @type {String}
     */
    rules: {
      type: Array,
      default: () => [],
    },

    /**
     * エラーメッセージを表示するか.
     * @type {Boolean}
     */
    errorVisible: {
      type: Boolean,
      default: true,
    },

    columnType: {
      type: Number,
      required: true,
    },

    options: {
      type: Array,
    },

    maxLength: {
      type: [String, Number],
    },

    minLength: {
      type: [String, Number],
    },

    minValue: {
      type: [String, Number],
    },

    maxValue: {
      type: [String, Number],
    },
  },

  computed: {
    _value: {
      get() {
        return this.value
      },

      set(v) {
        if (this.value !== v) this.$emit('input', v)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.base-checkbox {
  align-self: center;
  margin-left: 8px;
}
</style>
