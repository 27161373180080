/**
 * サービスアクセスのインターセプター.
 *
 * @file services/interceptor.js
 * @author Baek.JH (SBK)
 * @copyright Hitach Industry & Control Solutions, Ltd. All rights reserved.
 */

import store from '@/store'

/**
 * サービスアクセスのインターセプターを設定します.
 *
 * サーバーとの要求、応答に対して、共通処理を行います.
 *
 * @param {AxiosInstance} instance Axiosインスタンス
 */
function setInterceptor(instance) {
  instance.interceptors.request.use(
    (config) => config,
    (error) => Promise.reject(error)
  )
  instance.interceptors.response.use(
    (response) => {
      console.log(response)
      return response
    },
    (error) => {
      console.log(error)
      return Promise.reject(error)
    }
  )
}

/**
 * サービスアクセスのインターセプターを設定します.（認証後のアクセス用）
 *
 * サーバーとの要求、応答に対して、共通処理を行います.
 * 認証エラーの場合、ログイン画面に戻します.
 *
 * @param {AxiosInstance} instance Axiosインスタンス
 */
function setAuthenticationInterceptor(instance) {
  instance.interceptors.request.use(
    (config) => config,
    (error) => Promise.reject(error)
  )
  instance.interceptors.response.use(
    (response) => {
      console.log(response)
      return response
    },
    async (error) => {
      if (error.response) {
        // 応答コードが正常ではない場合（response.status != 2xx）
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
        if (error.response.status === 401) {
          if (!(error.config && error.config.url === '/logout')) {
            // ログイン画面に戻す前に、'再度ログインしてください。'旨のメッセージを表示する。
            alert(error.response.data.message)
            await store.dispatch('authentication/logout')
          }
        } else {
          return Promise.reject(error)
        }
      } else if (error.request) {
        // 応答なし
        console.log(error.request)
        return Promise.reject(error)
      } else {
        // 要求異常
        console.log('Error', error.message)
        return Promise.reject(error)
      }
      console.log(error.config)
    }
  )
}

export { setInterceptor, setAuthenticationInterceptor }
