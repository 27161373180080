<template>
  <b-modal
    :data-class="dataClass"
    no-close-on-backdrop
    no-close-on-esc
    :size="size"
    :title="title"
    @show="showModal"
    @hidden="afterClose"
    v-bind="$attrs"
    v-on="$listeners"
    :static="true"
  >
    <template v-slot:default>
      <slot name="main" />
    </template>
    <template v-slot:modal-footer="{ cancel }">
      <slot name="footer" v-bind:cancel="cancel" />
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'BaseModal',

  inheritAttrs: false,

  props: {
    dataClass: {
      type: String,
      default: '',
    },

    title: {
      type: String,
      default: '',
    },

    size: {
      type: String,
      default: '',
    },
  },
  methods: {
    /**
     * モーダルを閉じた後の処理です.
     */
    afterClose() {
      this.$emit('after-close')
    },
    /**
     * モーダルを開く時の処理です
     */
    showModal() {
      this.$emit('show-modal')
    },
  },
}
</script>

<style lang="scss" scoped></style>
