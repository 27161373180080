/**
 * 画面項目情報.
 *
 * @file system/init/ColumnInfo.js
 * @author Kim.HY (SBK)
 * @copyright Hitach Industry & Control Solutions, Ltd. All rights reserved.
 */

import { VISIBILITY } from '@/common/const.js'

/**
 * 項目定義情報.
 *
 * @class
 * @exports
 */
export default class ColumnInfo {
  label = null
  domainColumnName = null
  visibility = VISIBILITY.UNDEFINED
  width = -1
  displayOrder = -1

  /**
   * コンストラクタ.
   *
   * @constructor
   * @param {Object} params
   */
  constructor(params = {}) {
    Object.keys(params).forEach((key) => {
      this[key] = params[key]
    })
  }

  /**
   * 表示可能か判定して返します.
   *
   * @public
   * @return {Boolean} 表示可否
   */
  visible() {
    const visibleCtrlTypes = [
      VISIBILITY.UNDEFINED,
      VISIBILITY.VISIBLE,
      VISIBILITY.DISABLE,
    ]
    return visibleCtrlTypes.includes(this.visibility)
  }

  /**
   * 押下不可か判定して返します.
   *
   * @public
   * @return {Boolean} 押下可否
   */
  disable() {
    return this.visibility === VISIBILITY.DISABLE
  }
}
