<template>
  <validation-provider
    :rules="_rules"
    :name="columnInfo.label"
    v-slot="{ validated, touched, passed, errors }"
  >
    <b-form-checkbox
      v-if="columnInfo.visible()"
      v-model="_value"
      data-class="base-checkbox"
      size="sm"
      :disabled="columnInfo.disable() || disabled"
      :id="columnInfo.domainColumnName"
      :name="columnInfo.domainColumnName"
      :state="(state && validated) || (state && touched) ? passed : null"
      v-bind="$attrs"
      v-on="$listeners"
      class="ml-2 mt-1"
    >
      <span v-if="displayLabel">{{ columnInfo.label }}</span>
    </b-form-checkbox>
    <span v-if="errorVisible" class="text-danger">
      {{ errors[0] }}
    </span>
  </validation-provider>
</template>

<script>
export default {
  name: 'BaseCheckbox',

  inheritAttrs: false,

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    /**
     * 定義情報.
     * @type {Object}
     * @requires
     */
    columnInfo: {
      type: Object,
      required: true,
    },

    /**
     * 入力不可か.
     * @type {Boolean}
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * バリデーションチェックの状態を表示するか.
     * @type {Boolean}
     */
    state: {
      type: Boolean,
      default: false,
    },

    validated: {
      type: Boolean,
      default: true,
    },

    touched: {
      type: Boolean,
      default: true,
    },

    passed: {
      type: Boolean,
      default: false,
    },

    /**
     * 入力検証ルール.
     * @type {String}
     */
    rules: {
      type: Array,
      default: () => [],
    },

    /**
     * エラーメッセージを表示するか.
     * @type {Boolean}
     */
    errorVisible: {
      type: Boolean,
      default: true,
    },

    /**
     * ラベルを表示するか.
     * @type {Boolean}
     */
    displayLabel: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    _value: {
      get() {
        return this.value
      },

      set(v) {
        if (this.value !== v) this.$emit('input', v)
      },
    },

    /**
     * 入力検証ルール.
     * @return {String}
     */
    _rules() {
      let rules = ''
      this.rules.forEach((element) => {
        if (element == this.INPUT_TYPE_CHECK.REQUIRED) {
          rules += 'required|'
        }
      })
      return rules
    },
  },
}
</script>
