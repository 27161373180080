<template>
  <b-dropdown-item
    :class="{ 'disable-item': isDisable }"
    v-if="navItem.columnInfo.visible() && displayable"
    data-class="nav-dropdown-item"
    :disabled="isDisable"
    v-bind="$attrs"
    v-on="$listeners"
    @click="
      () => {
        if (!!navItem.onClickCallback) navItem.onClickCallback()
        if (navItem.screenId) {
          $router.push({ name: navItem.screenId })
        }
      }
    "
  >
    <BaseIcon v-if="!!navItem.icon" :name="navItem.icon" />
    <slot>
      {{ navItem.columnInfo.label }}
    </slot>
  </b-dropdown-item>
</template>

<script>
export default {
  name: 'BaseNavDropdownItem',

  inheritAttrs: false,

  props: {
    /**
     * ナビゲーション項目.
     * @type {Object}
     */
    navItem: {
      type: Object,
      required: true,
    },

    /**
     * 押下不可かどうか.
     * @type {Boolean}
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    /**
     * 表示可能かどうか.
     * ラベルかアイコンが指定されていれば表示可能とする.
     * @return {Boolean}
     */
    displayable() {
      return (
        (this.navItem.columnInfo.label != null &&
          typeof this.navItem.columnInfo.label === 'string' &&
          this.navItem.columnInfo.label.trim() != '') ||
        (this.navItem.icon != null &&
          typeof this.navItem.icon === 'string' &&
          this.navItem.icon.trim() != '')
      )
    },
    isDisable() {
      return (
        this.navItem.columnInfo.disable() ||
        this.disabled ||
        (!this.navItem.screenId && !this.navItem.onClickCallback)
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.disable-item {
  background-color: rgb(233, 230, 230);
}
</style>
