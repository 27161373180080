<template>
  <b-input-group size="sm" v-if="dateFromColumnInfo.visible()">
    <template v-slot:prepend v-if="labelVisible">
      <b-input-group-text class="group-text"
        >{{ dateFromColumnInfo.label }}
        <span v-if="dateFromRules == INPUT_TYPE_CHECK.REQUIRED">*</span>
      </b-input-group-text>
    </template>
    <BaseDateInput
      v-model="_dateFromValue"
      :columnInfo="dateFromColumnInfo"
      :error-visible="errorVisible"
      :rules="dateFromRules"
      :rangeTarget="rangeTarget"
      :state="state"
      :locale="locale"
    />
    <BaseTimeInput
      v-if="timeFromColumnInfo != null"
      v-model="_timeFromValue"
      :columnInfo="timeFromColumnInfo"
      :error-visible="errorVisible"
      :rules="timeFromRules"
      :state="state"
      :locale="locale"
    />
    {{ '～' }}
    <BaseDateInput
      v-model="_dateToValue"
      :columnInfo="dateToColumnInfo"
      :error-visible="errorVisible"
      :rules="dateToRules"
      :state="state"
      :locale="locale"
    />
    <BaseTimeInput
      v-if="timeToColumnInfo != null"
      v-model="_timeToValue"
      :columnInfo="timeToColumnInfo"
      :error-visible="errorVisible"
      :rules="timeToRules"
      :state="state"
      :locale="locale"
    />
  </b-input-group>
</template>

<script>
export default {
  name: 'BaseDateTimeFromTo',
  inheritAttrs: false,
  props: {
    labelVisible: {
      type: Boolean,
      default: false,
    },

    dateFromValue: {
      type: [String, Number],
      default: '',
    },

    dateToValue: {
      type: [String, Number],
      default: '',
    },

    timeFromValue: {
      type: String,
      default: '',
    },

    timeToValue: {
      type: String,
      default: '',
    },

    locale: {
      type: String,
      default: 'ja',
    },

    /**
     * 定義情報.
     * @type {Object}
     * @requires
     */
    dateFromColumnInfo: {
      type: Object,
      required: true,
    },

    dateToColumnInfo: {
      type: Object,
      required: true,
    },

    timeFromColumnInfo: {
      type: Object,
      default: null,
    },

    timeToColumnInfo: {
      type: Object,
      default: null,
    },

    /**
     * バリデーションチェックの状態を表示するか.
     * @type {Boolean}
     */
    state: {
      type: Boolean,
      default: false,
    },

    /**
     * エラーメッセージを表示するか.
     * @type {Boolean}
     */
    errorVisible: {
      type: Boolean,
      default: true,
    },

    /**
     * 入力検証ルール.
     * @type {String}
     */
    dateFromRules: {
      type: Array,
      default: () => [],
    },
    /**
     * 入力検証ルール.
     * @type {String}
     */
    dateToRules: {
      type: Array,
      default: () => [],
    },
    /**
     * 入力検証ルール.
     * @type {String}
     */
    timeFromRules: {
      type: Array,
      default: () => [],
    },
    /**
     * 入力検証ルール.
     * @type {String}
     */
    timeToRules: {
      type: Array,
      default: () => [],
    },

    rangeTarget: {
      type: String,
      default: null,
    },
  },
  computed: {
    _dateFromValue: {
      get() {
        return this.dateFromValue
      },
      set(v) {
        if (this.dateFromValue !== v) this.$emit('update:dateFromValue', v)
      },
    },
    _dateToValue: {
      get() {
        return this.dateToValue
      },
      set(v) {
        if (this.dateToValue !== v) this.$emit('update:dateToValue', v)
      },
    },
    _timeFromValue: {
      get() {
        return this.timeFromValue
      },
      set(v) {
        if (this.timeFromValue !== v) this.$emit('update:timeFromValue', v)
      },
    },
    _timeToValue: {
      get() {
        return this.timeToValue
      },
      set(v) {
        if (this.timeToValue !== v) this.$emit('update:timeToValue', v)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-border {
  border-left: 1px white solid;
}
</style>
