<template>
  <b-input-group size="sm" v-if="dateColumnInfo.visible()">
    <template v-slot:prepend v-if="labelVisible">
      <b-input-group-text class="group-text"
        >{{ dateColumnInfo.label }}
        <span v-if="dateRules == INPUT_TYPE_CHECK.REQUIRED">*</span>
      </b-input-group-text>
    </template>
    <BaseDateInput
      v-model="_dateValue"
      :columnInfo="dateColumnInfo"
      :error-visible="errorVisible"
      :rules="dateRules"
      :state="state"
    />
    <BaseTimeInput
      v-if="timeColumnInfo != null"
      v-model="_timeValue"
      :columnInfo="timeColumnInfo"
      :error-visible="errorVisible"
      :rules="timeRules"
      :state="state"
    />
  </b-input-group>
</template>

<script>
export default {
  name: 'BaseDateTime',
  inheritAttrs: false,
  props: {
    labelVisible: {
      type: Boolean,
      default: false,
    },

    dateValue: {
      type: [String, Number],
      default: '',
    },

    timeValue: {
      type: String,
      default: '',
    },

    /**
     * 定義情報.
     * @type {Object}
     * @requires
     */
    dateColumnInfo: {
      type: Object,
      required: true,
    },

    timeColumnInfo: {
      type: Object,
      default: null,
    },

    /**
     * バリデーションチェックの状態を表示するか.
     * @type {Boolean}
     */
    state: {
      type: Boolean,
      default: false,
    },

    /**
     * エラーメッセージを表示するか.
     * @type {Boolean}
     */
    errorVisible: {
      type: Boolean,
      default: true,
    },

    /**
     * 入力検証ルール.
     * @type {String}
     */
    dateRules: {
      type: Array,
      default: () => [],
    },
    /**
     * 入力検証ルール.
     * @type {String}
     */
    timeRules: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    _dateValue: {
      get() {
        return this.dateValue
      },
      set(v) {
        if (this.dateValue !== v) this.$emit('update:dateValue', v)
      },
    },
    _timeValue: {
      get() {
        return this.timeValue
      },
      set(v) {
        if (this.timeValue !== v) this.$emit('update:timeValue', v)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-border {
  border-left: 1px white solid;
}
</style>
